/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FaExclamationTriangle, FaEye, FaEyeSlash, FaChevronRight } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Select as SelectAntd, DatePicker, TimePicker, Breadcrumb } from 'antd';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/en_US';
import CompCollapsible from '../../../../Admin/components/Collapsible';
import { AppointmentApi } from '../../../../../services/api';
import { excludeRequest, scrollTop } from '../../../../Admin/assets/scripts/global';
import statesCities from '../../../../Website/assets/scripts/statesCities.json';
//import CompDatePicker, { datePickerRef } from '../../../../Admin/components/DatePicker';
import selectStyle from '../../../../Admin/components/Shared/selectStyle';
import { MenuList, ValueContainer } from '../../../../Admin/components/Shared/selectComponents';
import DynamicCard from '../../../../Admin/components/DynamicCards';
import './style.scss';
import country from '../../../../Website/assets/scripts/country.json';
import bank from '../../../../Website/assets/scripts/bank.json';

//let optionsFilled = false;

let profileOptions = [];

let stateOptions = [];
let cityOptions = [];
let countryOptions = [];

let bankOptions = [];
let contactTypeOptions = [];
let adressTypeOptions = [];

const contactOptions = [];
const addressOptions = [];
let addressesCardContent = [];
let dependenetsCardContent = [];
let contactsCardContent = [];

async function fillProfileOptions() {
  try{
    const response = await AppointmentApi.get('/Profiles');
    profileOptions = response.data.map((valueProfile) => ({
      label: valueProfile.st_name,
      value: valueProfile.cd_profile,
    }));
  } catch (err) {
    toast.error('Erro ao buscar perfis:', err);
  }
}

export default function CollaboratorRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.split('=').length > 1;
  const id = isEdit ? location.search.split('=')[1] : null;
  const dateFormat = 'DD/MM/YYYY';

  // Limpa o optionsFilled para realizar preenchimento dos campos novamente
  const handleNavigation = () => {
    if (location.pathname + location.search.split('=')[0] !== '/Apontamento/Colaboradores/Registro?cd_collaborator') {
      setOptionsFilled(false);
    }
  };

  // States de options
  const [optionsFilled, setOptionsFilled] = useState(false);
  const [stateAddressCityOptions, setStateAddressCityOptions] = useState();
  const [stateCityOptions, setStateCityOptions] = useState([]);

  const [stateProfileOptions, setStateProfileOptions] = useState([]);

  const [stateClientOptions, setStateClientOptions] = useState([]);
  const [stateJobRoleOptions, setStateJobRoleOptions] = useState([]);

  const [stateContactTypeOptions, setStateContactTypeOptions] = useState([]);
  const [stateAddressTypeOptions, setStateAdressTypeOptions] = useState([]);

  // States com os valores dos campos
  let valueAddressCity;
  const [typeOfAddress2, setTypeOfAddress2] = useState();
  const [valueAddressCity2, setValueAddressCity2] = useState('');

  const [stateProfile, setStateProfile] = useState({ value: null });
  const [stateHomeState, setStateHomeState] = useState({ value: null });

  const [stateHometown, setStateHometown] = useState({ value: null });
  const [stateValueCountry, setStateCountry] = useState({ value: null });
  const [stateIssuingState, setStateIssuingState] = useState({ value: null });
  const [stateMaritalStatus, setStateMaritalStatus] = useState({ value: null });
  const [stateEducationGrade, setStateEducationGrade] = useState({ value: null });
  const [stateSkinColor, setStateSkinColor] = useState({ value: null });
  const [stateSex, setStateSex] = useState({ value: null });

  const [stateWorkRegime, setStateWorkRegime] = useState({ value: null });
  const [stateClient, setStateClient] = useState({ value: null });
  const [stateJobRole, setStateJobRole] = useState({ value: null });

  const [stateAccountType, setStateAccountType] = useState({ value: null });
  const [stateBank, setStateBank] = useState({ value: null });

  let contactRef;
  let addressRef;

  const [stateBirthDate, setStateBirthDate] = useState();
  const [stateRegisterDate, setStateRegisterDate] = useState();
  const [stateShutdownDate, setStateShutdownDate] = useState();

  const [stateStartTime, setStateStartTime] = useState(moment('8:00', 'HH:mm'));
  const [stateEndTime, setStateEndTime] = useState(moment('17:30', 'HH:mm'));
  const [statePauseTime, setStatePauseTime] = useState(moment('1:00', 'HH:mm'));

  const [AddressesCardContent, setAddressesCardContent] = useState([]);
  const [ContactsCardContent, setContactsCardContent] = useState([]);
  const [dependentsCardContent, setDependentsCardContent] = useState([]);

  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  let typeOfAddress;
  const [stateOfAddress, setStateOfAddress] = useState('');
  const [valueDistrict, setValueDistrict] = useState('');
  const [valueNumber, setValueNumber] = useState('');
  const [valueCEP, setValueCEP] = useState('');
  const [valueStreet, setValueStreet] = useState('');
  const [valueComplement, setValueComplement] = useState('');
  const [valueDescription, setDescription] = useState('');
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditContact, setIsEditContacts] = useState(false);
  const [isEditDependent, setIsEditDependent] = useState(false);
  const [cardIdPag, setCardId] = useState('');
  const [loadingContent, setLoadingContent] = useState(false);
  const [valueName, setValueName] = useState('');
  const [valueCpf, setValueCpf] = useState('');
  const [valueDate, setValueDate] = useState();
  const [stateRelations, setValueRelations] = useState('');
  const [valueIncomeTax, setValueIncomeTax] = useState(false);

  bankOptions = [];
  bank.banks.forEach((banks) => {
    bankOptions.push({ label: banks.label, value: parseInt(banks.value, 10) });
  });

  countryOptions = [];
  country.countries.forEach((countries) => {
    countryOptions.push({ label: countries.nome_pais, value: countries.sigla });
  });

  stateOptions = [];
  statesCities.states.forEach((state) => {
    stateOptions.push({ label: state.name, value: state.acronym });
  });
  cityOptions = [];

  async function fulfillCitySelect(acronym, wich, cardId) {
    // Limpa as opções de cidade antes de preenchê-las
    cityOptions.length = 0;

    // Encontra o estado correspondente pela sigla (acronym)
    const state = statesCities.states.find((state) => state.acronym === acronym);

    if (state) {
      // Preenche as opções de cidade
      state.citys.forEach((city) => {
        cityOptions.push({ label: city, value: city });
      });

      // Atualiza as opções de cidade de acordo com o parâmetro `wich`
      if (wich === 1) {
        setStateCityOptions(cityOptions);
      } else {
        setLoadingAddress(true);
        await setStateAddressCityOptions(cityOptions);
        setLoadingAddress(false);

        // Se `cardId` for fornecido, atualiza as opções da cidade selecionada no cartão específico
        if (cardId !== undefined) {
          const valueCity = document
            .getElementsByClassName('contentAddressCep')[cardId].innerText.split('-')[2].trim();

          const valueCardRightCity = cityOptions.find((obj) => obj.label === valueCity);

          setLoadingAddress(true);
          await setValueAddressCity2(valueCardRightCity ? valueCardRightCity.value : undefined);
          setLoadingAddress(false);

          const cards = document.getElementsByClassName('card content-card');
          if (cards[cardId]) {
            cards[cardId].classList.add('content-card__selected');
          }
        }
      }
    }
  }

  async function fillContactTypeOptions() {
    setLoadingContact(true);
    await AppointmentApi.get('/ContactTypes/GetTypeContact')
      .then((response) => {
        setStateContactTypeOptions(response.data);
        contactTypeOptions = response.data;
      })
      .catch((err) => err);
    setLoadingContact(false);
  }

  async function fillAddressTypeOptions() {
    setLoadingAddress(true);
    await AppointmentApi.get('/AddressTypes/GetTypeAddress')
      .then((response) => {
        setStateAdressTypeOptions(response.data);
        adressTypeOptions = response.data;
      })
      .catch((err) => err);
    setLoadingAddress(false);
  }

  // Função para adicionar contato com dados da requisição
  const newContactCardEditReq = (data) => {
    // // Request para options de tipo de contato
    fillContactTypeOptions();
    const description = data.st_content;
    const typeFilter = contactTypeOptions.filter(({ value }) => value === data.cd_contact_type);
    const type = typeFilter.length > 0 ? typeFilter[0].label : undefined;

    if (description !== '' && type !== undefined) {
      // Monta o HTML que vai dentro dos cards
      const contactDescription = React.createElement(
        'p',
        { className: 'contentContactDescription' },
        description
      );
      const contactType = React.createElement('p', { className: 'contentContactType' }, type);
      const contactCard = React.createElement('div', { className: 'contact-card' }, [
        contactType,
        contactDescription,
      ]);

      contactsCardContent = [];
      // Adiciona o HTML no state
      setContactsCardContent(
        contactsCardContent.push({
          content: contactCard,
        })
      );
    }
  };

  // Função para adicionar endereço com dados da requisição
  const newAddressCardEditReq = async (data) => {
    setLoadingAddress(true);

    const typeFilter = adressTypeOptions.filter(({ value }) => value === data.cd_address_type);
    const type = typeFilter.length > 0 ? typeFilter[0].label : undefined;
    const cep = data.st_zip_code;
    const district = data.st_district;
    const street = data.st_street;
    const number = data.it_number;
    const details = data.st_address_details;
    const state = data.st_state;
    const city = data.st_city;

    if (cep.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);

      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);

      addressesCardContent = [];
      addressesCardContent.push({ content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);

      setLoadingAddress(false);
    }
  };

  // Função para adicionar dependente com dados da requisição
  const newDependentCardEditReq = async (data) => {
    setLoadingContent(true);

    const nameField = data.st_name;
    const cpfField = data.st_cpf;
    const birthdayField = moment(data.dt_birthday).format('DD/MM/YYYY');
    const relationshipField = data.st_relationship;
    const irField = data.fl_dependent_ir === 0 ? 'Não' : 'Sim';

    if (nameField !== '' && cpfField !== '') {
      // Monta o HTML que vai dentro dos cards
      const dependentName = React.createElement(
        'p',
        { className: 'contentDependentName' },
        nameField
      );
      const dependentCpf = React.createElement('p', { className: 'contentDependentCpf' }, cpfField);
      const dependentBirthday = React.createElement(
        'p',
        { className: 'contentDependentBirthday' },
        `Data de Nascimento: ${birthdayField}`
      );
      const dependentRelationship = React.createElement(
        'p',
        { className: 'contentDependentRelationship' },
        `Parentesco: ${relationshipField}`
      );
      const dependentIr = React.createElement(
        'p',
        { className: 'contentDependentIr' },
        `Imposto de renda - ${irField}`
      );
      const dependentCard = React.createElement('div', { className: 'dependent-card' }, [
        dependentName,
        dependentCpf,
        dependentBirthday,
        dependentRelationship,
        dependentIr,
      ]);

      dependenetsCardContent = [];
      // Adiciona o HTML no state
      dependenetsCardContent.push({ content: dependentCard });
      // Adiciona o HTML no state
      await setDependentsCardContent(dependenetsCardContent);
      setLoadingContent(false);
    }
  };

  async function fillFields(data) {
    // Dados
    document.getElementById('nameField').value = data.userEntity.st_name;
    document.getElementById('usernameField').value = data.userEntity.st_login;
    document.getElementById('emailField').value = data.userEntity.st_email;
    document.getElementById('cpfField').value = data.userEntity.st_cpf;
    setStateBirthDate(moment(data.userEntity.dt_birthday));
    document.getElementById('passwordField').value = data.userEntity.st_password;
    document.getElementById('confirmPasswordField').value = data.userEntity.st_password;
    setStateProfile({ label: data.st_profile, value: data.userEntity.cd_profile });

    // // Dados Pessoais
    document.getElementById('socialNameField').value = data.collaboratorEntity.st_social_name;
    document.getElementById('rgField').value = data.collaboratorEntity.st_rg_number;
    document.getElementById('issuingAgencyField').value = data.collaboratorEntity.st_rg_organ;
    document.getElementById('pisField').value = data.collaboratorEntity.st_pis_number;
    document.getElementById('fatherNameField').value = data.collaboratorEntity.st_father_name;
    document.getElementById('motherNameField').value = data.collaboratorEntity.st_mother_name;
    setStateIssuingState(data.collaboratorEntity.st_rg_state);
    setStateCountry(data.collaboratorEntity.st_birth_country);
    setStateHomeState(data.collaboratorEntity.st_birth_state);
    fulfillCitySelect(data.collaboratorEntity.st_birth_state, 1);
    setStateHometown(data.collaboratorEntity.st_birth_city);
    setStateMaritalStatus(data.collaboratorEntity.st_marital_status);
    setStateEducationGrade(data.collaboratorEntity.st_education_grade);
    setStateSkinColor(data.collaboratorEntity.st_skin_color);
    setStateSex(data.collaboratorEntity.st_sex);

    // // Dados Administrativos
    setStateRegisterDate(moment(data.collaboratorEntity.dt_register));
    setStateShutdownDate(moment(data.collaboratorEntity.dt_shutdown));
    setStateStartTime(moment(data.collaboratorEntity.ti_time_entry, 'HH:mm'));
    setStateEndTime(moment(data.collaboratorEntity.ti_time_exit, 'HH:mm'));
    setStatePauseTime(moment(data.collaboratorEntity.ti_time_break, 'HH:mm'));
    document.getElementById('valuationHoursField').value = data.collaboratorEntity.dc_hour_value;
    setStateWorkRegime({ value: data.collaboratorEntity.cd_work_regime });
    setStateClient({ value: data.collaboratorEntity.cd_juridical_person });
    setStateJobRole({ value: data.collaboratorEntity.cd_job_role });

    // // Dados Bancários
    setStateAccountType(data.collaboratorEntity.st_bank_type);
    setStateBank({ value: data.collaboratorEntity.st_bank });
    document.getElementById('bankAgencyField').value = data.collaboratorEntity.st_bank_agency;
    document.getElementById('bankAgencyDigitField').value =
      data.collaboratorEntity.st_bank_agency_digit;
    document.getElementById('bankAccountField').value = data.collaboratorEntity.st_bank_account;
    document.getElementById('bankAccountDigitField').value =
      data.collaboratorEntity.st_bank_account_digit;

    // Contato
    data.contactList?.map((dataContact) => newContactCardEditReq(dataContact));

    // Endereço
    data.addressList?.map((dataAddress) => newAddressCardEditReq(dataAddress));

    // Dependentes
    data.dependentList?.map((dataDependets) => newDependentCardEditReq(dataDependets));
  }

  function fillFieldsContacts(Contacts) {
    Contacts.forEach(({ cd_contact_type, st_content }) => {
      const { label: contactTypeName } = contactTypeOptions.find(
        ({ value }) => value === cd_contact_type
      );

      // Monta o HTML que vai dentro dos cards
      const contactDescription = React.createElement(
        'p',
        { className: 'contentContactDescription' },
        st_content
      );
      const contactType = React.createElement(
        'p',
        { className: 'contentContactType' },
        contactTypeName
      );
      const contactCard = React.createElement('div', { className: 'contact-card' }, [
        contactType,
        contactDescription,
      ]);

      // Adiciona o HTML no state
      setContactsCardContent(
        contactsCardContent.push({
          content: contactCard,
        })
      );
    });
  }

  function fillFieldsAddresses(addresses) {
    addresses.forEach(
      ({
        st_zip_code,
        st_district,
        st_street,
        cd_address_type,
        it_number,
        st_address_details,
        st_state,
        st_city,
      }) => {
        const { label: adressTypeName } = stateAddressTypeOptions.find(
          ({ value }) => value === cd_address_type
        );

        // Monta o HTML que vai dentro dos cards
        const addressType = React.createElement(
          'p',
          { className: 'contentAddressType' },
          adressTypeName
        );
        const addressNumber = React.createElement(
          'span',
          { className: 'contentAddressNumber' },
          it_number
        );
        const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
          `${st_street}, `,
          addressNumber,
        ]);
        const addressDistrict = React.createElement(
          'p',
          { className: 'contentAddressDistrict' },
          st_district
        );
        const addressDetails = React.createElement(
          'p',
          { className: 'contentAddressDetails' },
          st_address_details
        );
        const addressState = React.createElement('span', {}, st_state);
        const addressCity = React.createElement('span', {}, [`${st_city} - `, addressState]);
        const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
          `${st_zip_code} - `,
          addressCity,
        ]);
        const addressCard = React.createElement('div', { className: 'address-card' }, [
          addressType,
          addressStreet,
          addressDistrict,
          addressDetails,
          addressCep,
        ]);

        addressesCardContent.push({ content: addressCard });
        // Adiciona o HTML no state
        setAddressesCardContent(addressesCardContent);
      }
    );
  }

  function fillFieldsDependents(dependents) {
    dependents.forEach(
      async ({ st_name, st_cpf, dt_birthday, st_relationship, fl_dependent_ir }) => {
        // Monta o HTML que vai dentro dos cards
        const dependentName = React.createElement(
          'p',
          { className: 'contentDependentName' },
          st_name
        );
        const dependentCpf = React.createElement('p', { className: 'contentDependentCpf' }, st_cpf);
        const dependentBirthday = React.createElement(
          'p',
          { className: 'contentDependentBirthday' },
          `Data de Nascimento: ${dt_birthday}`
        );
        const dependentRelationship = React.createElement(
          'p',
          { className: 'contentDependentRelationship' },
          `Parentesco: ${st_relationship}`
        );
        const dependentIr = React.createElement(
          'p',
          { className: 'contentDependentIr' },
          `Imposto de renda - ${fl_dependent_ir}`
        );
        const dependentCard = React.createElement('div', { className: 'dependent-card' }, [
          dependentName,
          dependentCpf,
          dependentBirthday,
          dependentRelationship,
          dependentIr,
        ]);

        dependenetsCardContent.push({ content: dependentCard });
        // Adiciona o HTML no state
        setDependentsCardContent(dependenetsCardContent);
      }
    );
  }

  // Requisição para trazer o colaborador pelo id
  async function getCollaboratorById() {
    await AppointmentApi.get('/Collaborators', {
      params: { id },
    })
      .then((response) => {
        fillFields(response.data);
      })
      .catch((err) => err);
  }

  async function getContactsById() {
    await AppointmentApi.get(`/Contacts/GetByTypeID?id=${id}&user=1&tipoDePessoa=collaborator`)
      .then((response) => {
        fillFieldsContacts(response.data);
      })
      .catch((err) => err);
  }

  async function getAddressById() {
    await AppointmentApi.get(`/Address/GetByTypeID?id=${id}&user=1&tipoDePessoa=collaborator`)
      .then((response) => {
        fillFieldsAddresses(response.data);
      })
      .catch((err) => err);
  }

  async function getDependentsById() {
    await AppointmentApi.get(
      `/CollaboratorDependents/GetByTypeID?id=${id}&user=1&tipoDePessoa=collaborator`
    )
      .then((response) => {
        console.log(response.data);
        fillFieldsDependents(response.data);
      })
      .catch((err) => err);
  }

  async function fillClientOptions() {
    await AppointmentApi.get('/JuridicalPersons/GetSelectOptions?type=2')
      .then((response) => {
        setStateClientOptions(response.data);
      })
      .catch((err) => err);
  }

  async function fillJobRoleOptions() {
    await AppointmentApi.get('/JobRoles/GetJobRole')
      .then((response) => {
        setStateJobRoleOptions(response.data);
      })
      .catch((err) => err);
  }
  // lista de opções
  const StatusMaritalOptions = [
    {
      label: 'Solteiro(a)',
      value: 'Solteiro(a)',
    },
    {
      label: 'Casado(a)',
      value: 'Casado(a)',
    },
    {
      label: 'Separado(a)',
      value: 'Separado(a)',
    },
    {
      label: 'Divorciado(a)',
      value: 'Divorciado(a)',
    },
    {
      label: 'Viúvo(a)',
      value: 'Viúvo(a)',
    },
  ];

  const EducationGradeOptions = [
    {
      label: 'Fundamental Completo',
      value: 'Fundamental Completo',
    },
    {
      label: 'Fundamental Cursando',
      value: 'Fundamental Cursando',
    },
    {
      label: 'Fundamental Incompleto',
      value: 'Fundamental Incompleto',
    },
    {
      label: 'Médio Completo',
      value: 'Médio Completo',
    },
    {
      label: 'Médio Cursando',
      value: 'Médio Cursando',
    },
    {
      label: 'Médio Incompleto',
      value: 'Médio Incompleto',
    },
    {
      label: 'Superior Completo',
      value: 'Superior Completo',
    },
    {
      label: 'Superior Cursando',
      value: 'Superior Cursando',
    },
    {
      label: 'Superior Incompleto',
      value: 'Superior Incompleto',
    },
    {
      label: 'Mestrado',
      value: 'Mestrado',
    },
    {
      label: 'Doutorado',
      value: 'Doutorado',
    },
  ];
  const SkinColorOptions = [
    {
      label: 'Amarelo',
      value: 'Amarelo',
    },
    {
      label: 'Branco',
      value: 'Branco',
    },
    {
      label: 'Indigena',
      value: 'Indigena',
    },
    {
      label: 'Negro',
      value: 'Negro',
    },
    {
      label: 'Pardo',
      value: 'Pardo',
    },
  ];

  const relationshipOptions = [
    {
      label: 'Pai',
      value: 'Pai',
    },
    {
      label: 'Mãe',
      value: 'Mãe',
    },
    {
      label: 'Filho/filha',
      value: 'Filho/filha',
    },
    {
      label: 'Irmão/irmã',
      value: 'Irmão/irmã',
    },
    {
      label: 'Tio/tia',
      value: 'Tio/tia',
    },
    {
      label: 'Avô/avó',
      value: 'Avô/avó',
    },
  ];

  const SexOptions = [
    {
      label: 'Feminino',
      value: 'Feminino',
    },
    {
      label: 'Masculino',
      value: 'Masculino',
    },
  ];
  const WorkRegimeOptions = [
    {
      label: 'Híbrido',
      value: 3,
    },
    {
      label: 'Home Office',
      value: 2,
    },
    {
      label: 'Presencial',
      value: 1,
    },
  ];
  const AccountTypeOptions = [
    {
      label: 'Corrente',
      value: 'Corrente',
    },
    {
      label: 'Poupança',
      value: 'Poupança',
    },
    {
      label: 'Salário',
      value: 'Salário',
    },
  ];

  // Exibe/esconde a senha
  function toggleEye() {
    const pswd = document.getElementById('passwordField');

    document.getElementById('pswdEye').classList.toggle('is-normal');
    if (pswd.getAttribute('type') === 'password') {
      pswd.setAttribute('type', 'text');
    } else {
      pswd.setAttribute('type', 'password');
    }
  }

  function toggleEye2() {
    const pswd2 = document.getElementById('confirmPasswordField');
    document.getElementById('pswdEye2').classList.toggle('is-normal');
    if (pswd2.getAttribute('type') === 'password') {
      pswd2.setAttribute('type', 'text');
    } else {
      pswd2.setAttribute('type', 'password');
    }
  }

  // Fill address fields by CEP
  async function getAddressByCep(cep) {
    if (cep.length > 8) {
      await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (!res.erro) {
            document.getElementById('streetField').value = res.logradouro;
            document.getElementById('districtField').value = res.bairro;
            await fulfillCitySelect(res.uf, 2);
            setLoadingAddress(true);
            await setStateOfAddress(res.uf);
            await setValueAddressCity2(res.localidade);
            await setValueDistrict(res.bairro);
            await setValueStreet(res.logradouro);
            setLoadingAddress(false);
          } else {
            const formResponse = document.getElementById('response');
            formResponse.classList.remove('alert-success');
            formResponse.classList.add('alert-danger');
            formResponse.innerText = 'CEP não encontrado!';
            formResponse.style.display = 'block';
            setTimeout(() => {
              formResponse.style.display = 'none';
            }, 4000);
          }
        })
        .catch((err) => err);
    }
  }

  // Limpa campos Address
  const clearAddressFields = async () => {
    if (document.getElementById('cepField')) {
      document.getElementById('cepField').value = '';
      document.getElementById('districtField').value = '';
      document.getElementById('streetField').value = '';
      document.getElementById('numberField').value = '';
      document.getElementById('addressDetailsField').value = '';
      setLoadingAddress(true);
      setTypeOfAddress2(null);
      setValueAddressCity2(null);
      setStateOfAddress(undefined);
      setValueNumber('');
      setValueDistrict('');
      setValueCEP('');
      setValueComplement('');
      setIsEditAddress(false);
      setLoadingAddress(false);
    }
  };

  // Função para atualizar o state addressesCardContent
  const updateAddressesState = () => {
    setAddressesCardContent(addressesCardContent);
  };

  // Função para preencher os campos de endereço
  const fillAddressFields = (cardId) => {
    setCardId(cardId);
    setIsEditAddress(true);

    const valueTypeAddress =
      document.getElementsByClassName('contentAddressType')[cardId].innerText;
    setTypeOfAddress2(valueTypeAddress);

    const valueState = document
      .getElementsByClassName('contentAddressCep')
      [cardId].innerText.split('-')[3];

    const teste = stateOptions.filter((obj) => obj.value === valueState.trim());
    setStateOfAddress(teste.length > 0 ? teste[0] : undefined);
    fulfillCitySelect(teste.length > 0 ? teste[0].value : undefined, 2, cardId);

    const contentAddressCep = document
      .getElementsByClassName('contentAddressCep')
      [cardId].firstChild.data.split(' ')[0];

    const contentAddressNumber =
      document.getElementsByClassName('contentAddressNumber')[cardId].innerText;
    const contentAddressDetails =
      document.getElementsByClassName('contentAddressDetails')[cardId].innerText;
    const contentAddressDistrict =
      document.getElementsByClassName('contentAddressDistrict')[cardId].innerText;
    const contentAddressStreet = document
      .getElementsByClassName('contentAddressStreet')
      [cardId].innerText.split(',')[0];

    setValueDistrict(contentAddressDistrict);
    setValueStreet(contentAddressStreet);
    setValueCEP(contentAddressCep);
    setValueNumber(contentAddressNumber);
    setValueComplement(contentAddressDetails);
  };

  // Função para editar os campos de endereço
  const editAddressCard = async (collaboratorId) => {
    const type = typeOfAddress2;
    const cep = document.getElementById('cepField');
    const district = document.getElementById('districtField');
    const street = document.getElementById('streetField');
    const number = document.getElementById('numberField');
    const details = document.getElementById('addressDetailsField');
    const state = stateOfAddress.value;
    const city = valueAddressCity2;

    if (cep.value.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);
      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number.value
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street.value}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district.value
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details.value
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep.value} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);
      if (isEdit) {
        const data = {
          cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
          Cd_collaborator: collaboratorId,
          St_zip_code: cep.innerText,
          Cd_address_type: addressType.value,
          St_state: state,
          St_city: valueAddressCity2,
          St_district: district.value,
          St_street: street.value,
          It_number: number.value,
          St_address_details: details.value,
          It_subject_type: 1,
          It_subject: 1,
        };

        await AppointmentApi({
          method: 'PUT',
          url: '/Address',
          data,
        })
          .then((res) => res)
          .catch((err) => {
            toast.error(err);
          });
      }

      addressesCardContent.splice(cardIdPag, 1, { content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);
    }
  };

  // Função para adicionar endereço
  const newAddressCard = async () => {
    const type = typeOfAddress2;
    const cep = document.getElementById('cepField');
    const district = document.getElementById('districtField');
    const street = document.getElementById('streetField');
    const number = document.getElementById('numberField');
    const details = document.getElementById('addressDetailsField');
    const state = stateOfAddress;
    const city = valueAddressCity2;

    if (cep.value.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);
      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number.value
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street.value}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district.value
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details.value
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep.value} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);

      addressesCardContent.push({ content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);
      // Limpa os campos
      clearAddressFields();
    }
  };

  // Limpa campos Contact
  const clearContactFields = () => {
    document.getElementById('descriptionField').value = '';
    setLoadingContact(true);
    setIsEditContacts(false);
    setDescription('');
    setLoadingContact(false);
  };

  // Função para atualizar o state contactsCardContent
  const updateContactsState = () => {
    setContactsCardContent(contactsCardContent);
  };

  // Função para preencher os campos de contato
  const fillContactFields = (cardId, currentId) => {
    const inputCurrentId = document.getElementById(currentId);
    const contentContactDescription = document.getElementsByClassName('contentContactDescription')[
      cardId
    ].innerText;
    const valueTypeContact =
      document.getElementsByClassName('contentContactType')[cardId].innerText;
    contactRef.select.setValue(
      stateContactTypeOptions.filter((obj) => obj.label === valueTypeContact)
    );
    inputCurrentId.value = cardId;
    document.getElementById('descriptionField').value = contentContactDescription;
  };

  // Função para editar os campos de contato
  const editContactCard = async (currentId) => {
    const contentContactDescription = document.getElementsByClassName('contentContactDescription')[
      currentId
    ];
    const valueTypeContact = document.getElementsByClassName('contentContactType')[currentId];
    valueTypeContact.innerText = contactRef.select.getValue()[0].label;
    contentContactDescription.innerText = document.getElementById('descriptionField').value;

    if (isEdit) {
      const data = {
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        Cd_collaborator: id,
        St_content: contentContactDescription.innerText,
        Cd_contact_type: valueTypeContact.value,
        It_subject_type: 1,
        It_subject: 1,
      };

      await AppointmentApi({
        method: 'PUT',
        url: '/Contacts',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  //Função para adicionar contato
  // const newContactCard = () => {
  //   const description = document.getElementById('descriptionField');
  //   const type = contactRef.select.getValue()[0].label;
  //
  //   if (description.value !== '' && type !== undefined) {
  //     // Monta o HTML que vai dentro dos cards
  //     const contactDescription = React.createElement(
  //       'p',
  //       { className: 'contentContactDescription' },
  //       description.value
  //     );
  //     const contactType = React.createElement('p', { className: 'contentContactType' }, type);
  //     const contactCard = React.createElement('div', { className: 'contact-card' }, [
  //       contactType,
  //       contactDescription,
  //     ]);
  //
  //     // Adiciona o HTML no state
  //     setContactsCardContent(
  //       contactsCardContent.push({
  //         content: contactCard,
  //       })
  //     );
  //
  //     // Limpa os campos
  //     clearContactFields();
  //   }
  // };

  const newContactCard = () => {
    const description = document.getElementById('descriptionField');

    // Verifica se contactRef e contactRef.select estão definidos
    if (contactRef && contactRef.select) {
      const type = contactRef.select.getValue()[0]?.label;

      if (description.value !== '' && type !== undefined) {
        // Monta o HTML que vai dentro dos cards
        const contactDescription = React.createElement(
          'p',
          { className: 'contentContactDescription' },
          description.value
        );
        const contactType = React.createElement('p', { className: 'contentContactType' }, type);
        const contactCard = React.createElement('div', { className: 'contact-card' }, [
          contactType,
          contactDescription,
        ]);

        // Adiciona o HTML no state
        setContactsCardContent([
          ...contactsCardContent,
          {
            content: contactCard,
          },
        ]);

        // Limpa os campos
        clearContactFields();
      } else {
        toast.error('Description or type is missing');
      }
    } else {
      toast.error('contactRef ou contactRef.select retornou undefined');
    }
  };


  // Limpa campos Dependent
  const clearDependentFields = async () => {
    document.getElementById('dependentNameField').value = '';
    document.getElementById('dependentCpfField').value = '';
    document.getElementById('dependentIrCheckbox').checked = false;
    document.getElementById('birthDate').value = '';
    setLoadingContent(true);
    setValueIncomeTax(false);
    await setValueName('');
    await setValueCpf('');
    await setValueDate(undefined);
    await setValueRelations(undefined);
    await setIsEditDependent(false);
    setLoadingContent(false);
  };

  // Função para atualizar o state dependentsCardContent
  const updateDependentsState = () => {
    setDependentsCardContent(dependenetsCardContent);
  };

  // Função para preencher os campos de dependente
  const fillDependentFields = (cardId) => {
    setCardId(cardId);

    setIsEditDependent(true);
    setLoadingContent(true);
    const contentDependentName =
      document.getElementsByClassName('contentDependentName')[cardId].innerText;
    const contentDependentCpf =
      document.getElementsByClassName('contentDependentCpf')[cardId].innerText;
    const contentDependentBirthday = document
      .getElementsByClassName('contentDependentBirthday')
      [cardId].innerText.split(': ')[1];
    const contentDependentRelationship = document
      .getElementsByClassName('contentDependentRelationship')
      [cardId].innerText.split(': ')[1];
    const contentDependentIr = document
      .getElementsByClassName('contentDependentIr')
      [cardId].innerText.split(' - ')[1];

    // document.getElementById('dependentNameField').value = contentDependentName;
    // document.getElementById('dependentCpfField').value = contentDependentCpf;
    // document.getElementById('birthDate').value = contentDependentBirthday;
    // document.getElementById('relationship').value = contentDependentRelationship;

    setValueName(contentDependentName);
    setValueCpf(contentDependentCpf);
    setValueDate(moment(contentDependentBirthday, 'DD/MM/YYYY'));
    setValueRelations(contentDependentRelationship);
    if (contentDependentIr === 'Sim') {
      setValueIncomeTax(true);
    } else {
      setValueIncomeTax(false);
    }
    setLoadingContent(false);
  };

  // Função para editar os campos de dependente
  const editDependentCard = async () => {
    const nameField = document.getElementById('dependentNameField');
    const cpfField = document.getElementById('dependentCpfField');
    const birthdayField = moment(valueDate).format('DD/MM/YYYY');
    const relationshipField = stateRelations.value;
    const irField = document.getElementById('dependentIrCheckbox').checked;
    let irFieldValue = 'Não';
    if (irField) {
      irFieldValue = 'Sim';
    }

    if (nameField.value !== '' && cpfField.value !== '') {
      // Monta o HTML que vai dentro dos cards
      const dependentName = React.createElement(
        'p',
        { className: 'contentDependentName' },
        nameField.value
      );
      const dependentCpf = React.createElement(
        'p',
        { className: 'contentDependentCpf' },
        cpfField.value
      );
      const dependentBirthday = React.createElement(
        'p',
        { className: 'contentDependentBirthday' },
        `Data de Nascimento: ${birthdayField}`
      );
      const dependentRelationship = React.createElement(
        'p',
        { className: 'contentDependentRelationship' },
        `Parentesco: ${relationshipField}`
      );
      const dependentIr = React.createElement(
        'p',
        { className: 'contentDependentIr' },
        `Imposto de renda - ${irFieldValue}`
      );
      const dependentCard = React.createElement('div', { className: 'dependent-card' }, [
        dependentName,
        dependentCpf,
        dependentBirthday,
        dependentRelationship,
        dependentIr,
      ]);

      dependenetsCardContent.splice(cardIdPag, 1, { content: dependentCard });
      // Adiciona o HTML no state
      await setDependentsCardContent(dependenetsCardContent);
    }
  };

  // Função para adicionar dependente
  const newDependentCard = async () => {
    const nameField = document.getElementById('dependentNameField');
    const cpfField = document.getElementById('dependentCpfField');
    const birthdayField = moment(valueDate).format('DD/MM/YYYY');
    const relationshipField = stateRelations;
    const irField = document.getElementById('dependentIrCheckbox').checked;
    let irFieldValue = 'Não';

    if (irField) {
      irFieldValue = 'Sim';
    }

    if (nameField.value !== '' && cpfField.value !== '') {
      // Monta o HTML que vai dentro dos cards
      const dependentName = React.createElement(
        'p',
        { className: 'contentDependentName' },
        nameField.value
      );
      const dependentCpf = React.createElement(
        'p',
        { className: 'contentDependentCpf' },
        cpfField.value
      );
      const dependentBirthday = React.createElement(
        'p',
        { className: 'contentDependentBirthday' },
        `Data de Nascimento: ${birthdayField}`
      );
      const dependentRelationship = React.createElement(
        'p',
        { className: 'contentDependentRelationship' },
        `Parentesco: ${relationshipField}`
      );
      const dependentIr = React.createElement(
        'p',
        { className: 'contentDependentIr' },
        `Imposto de renda - ${irFieldValue}`
      );
      const dependentCard = React.createElement('div', { className: 'dependent-card' }, [
        dependentName,
        dependentCpf,
        dependentBirthday,
        dependentRelationship,
        dependentIr,
      ]);

      dependenetsCardContent.push({ content: dependentCard });
      // Adiciona o HTML no state
      await setDependentsCardContent(dependenetsCardContent);

      // Limpa os campos
      clearDependentFields();
    }
  };

  function validateHour(timeEntry, timeExit) {
    let validHour = false;
    const hourEntry = timeEntry.split(':');
    const hourLeft = timeExit.split(':');

    parseInt(hourEntry, 10);
    parseInt(hourLeft, 10);

    if (timeEntry === '') {
      validHour = false;
    } else if (timeExit === '') {
      validHour = false;
    } else if (hourEntry[0] > hourLeft[0] || undefined) {
      validHour = false;
    } else if (hourEntry[0] === hourLeft[0] && hourEntry[1] > hourLeft[1]) {
      validHour = false;
    } else {
      validHour = true;
    }
    return validHour;
  }
  function validateFields() {
    let isValid = true;

    const name = document.getElementById('nameField');
    const userName = document.getElementById('usernameField');
    const email = document.getElementById('emailField');
    const cpf = document.getElementById('cpfField');
    const birthDate = document.getElementById('birthDate').parentNode.parentNode;
    const profile = document.getElementById('profileField').parentNode.parentNode;
    const password = document.getElementById('passwordField');
    const confirmPassword = document.getElementById('confirmPasswordField');

    const registerDate = document.getElementById('registerDate').parentNode.parentNode;
    const timeStart = document.getElementById('timeStart').parentNode.parentNode;
    const timeEnd = document.getElementById('timeEnd').parentNode.parentNode;
    const timePause = document.getElementById('timeBreak').parentNode.parentNode;
    const valueHour = document.getElementById('valuationHoursField');
    const workRegime = document.getElementById('workRegime').parentNode.parentNode;
    const client = document.getElementById('client').parentNode.parentNode;
    const jobRole = document.getElementById('jobRole').parentNode.parentNode;

    const accountType = document.getElementById('accountType').parentNode.parentNode;

    const agency = document.getElementById('bankAgencyField');
    const agencyDigit = document.getElementById('bankAgencyDigitField');
    const account = document.getElementById('bankAccountField');
    const accountDigit = document.getElementById('bankAccountDigitField');

    if (
      !validateHour(
        document.getElementById('timeStart').value,
        document.getElementById('timeEnd').value
      )
    ) {
      timeEnd.classList.add('border-danger');
      timeStart.classList.add('border-danger');
      toast.error('Hora inválida');
      isValid = false;
    } else {
      timeStart.classList.remove('border-danger');
      timeEnd.classList.remove('border-danger');
      isValid = true;
    }

    if (name.value === '' || null) {
      name.classList.add('border-danger');
      isValid = false;
    } else {
      name.classList.remove('border-danger');
      isValid = true;
    }

    if (userName.value === '' || null) {
      userName.classList.add('border-danger');
      isValid = false;
    } else {
      userName.classList.remove('border-danger');
      isValid = true;
    }

    if (email.value === '' || null) {
      email.classList.add('border-danger');
      isValid = false;
    } else {
      email.classList.remove('border-danger');
      isValid = true;
    }

    if (cpf.value === '' || null) {
      cpf.classList.add('border-danger');
      isValid = false;
    } else {
      cpf.classList.remove('border-danger');
      isValid = true;
    }

    if (stateBirthDate === undefined || stateBirthDate === null) {
      birthDate.classList.add('border-danger');
      isValid = false;
    } else {
      birthDate.classList.remove('border-danger');
      isValid = true;
    }

    if (stateProfile.value === '' || stateProfile.value === null) {
      profile.classList.add('border-danger');
      isValid = false;
    } else {
      profile.classList.remove('border-danger');
      isValid = true;
    }

    if (password.value === '' || null) {
      password.classList.add('border-danger');
      isValid = false;
    } else {
      password.classList.remove('border-danger');
      isValid = true;
    }

    if (confirmPassword.value === '' || null) {
      confirmPassword.classList.add('border-danger');
      isValid = false;
    } else {
      confirmPassword.classList.remove('border-danger');
      isValid = true;
    }

    if (stateRegisterDate === undefined || stateRegisterDate === null) {
      registerDate.classList.add('border-danger');
      isValid = false;
    } else {
      registerDate.classList.remove('border-danger');
      isValid = true;
    }

    const TimePause = document.getElementById('timeBreak').value;
    if (!TimePause || TimePause === '' || TimePause === undefined) {
      timePause.classList.add('border-danger');
      isValid = false;
    } else {
      timePause.style.border = '0px';
    }

    if (valueHour.value === '' || null) {
      valueHour.classList.add('border-danger');
      isValid = false;
    } else {
      valueHour.classList.remove('border-danger');
      isValid = true;
    }

    if (stateWorkRegime === undefined || stateWorkRegime === null) {
      workRegime.classList.add('border-danger');
      isValid = false;
    } else {
      workRegime.classList.remove('border-danger');
      isValid = true;
    }

    if (stateClient === undefined || stateClient === null) {
      client.classList.add('border-danger');
      isValid = false;
    } else {
      client.classList.remove('border-danger');
      isValid = true;
    }

    if (stateAccountType === undefined || stateAccountType === null) {
      accountType.classList.add('border-danger');
      isValid = false;
    } else {
      accountType.classList.remove('border-danger');
      isValid = true;
    }

    if (stateJobRole === undefined || stateJobRole === null) {
      jobRole.classList.add('border-danger');
      isValid = false;
    } else {
      jobRole.classList.remove('border-danger');
      isValid = true;
    }

    if (agency.value === '' || null) {
      agency.classList.add('border-danger');
      isValid = false;
    } else {
      agency.classList.remove('border-danger');
      isValid = true;
    }

    if (agencyDigit.value === '' || null) {
      agencyDigit.classList.add('border-danger');
      isValid = false;
    } else {
      agencyDigit.classList.remove('border-danger');
      isValid = true;
    }

    if (account.value === '' || null) {
      account.classList.add('border-danger');
      isValid = false;
    } else {
      account.classList.remove('border-danger');
      isValid = true;
    }

    if (accountDigit.value === '' || null) {
      accountDigit.classList.add('border-danger');
      isValid = false;
    } else {
      accountDigit.classList.remove('border-danger');
      isValid = true;
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos');
    }

    return isValid;
  }

  async function insertContacts(collaboratorId) {
    const contentContactDescription = document.getElementsByClassName('contentContactDescription');
    const contentContactType = document.getElementsByClassName('contentContactType');

    for (let i = 0; i < contentContactDescription.length; i += 1) {
      const contactType = stateContactTypeOptions.filter(
        ({ label }) => label === contentContactType[i].innerText
      )[0];

      const data = {
        Cd_collaborator: collaboratorId,
        It_subject_type: 1,
        It_subject: 1,
        St_content: contentContactDescription[i].innerText,
        Cd_contact_type: contactType.value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };

      // try{
      //   const response = AppointmentApi({
      //     method: 'POST',
      //     url: '/Contacts',
      //     data,
      //   });
      //   return response;
      // } catch (err) {
      //   toast.error(err.response.message);
      // }

      await AppointmentApi({
        method: 'POST',
        url: '/Contacts',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  async function insertAddress(collaboratorId) {
    const contentAddressType = document.getElementsByClassName('contentAddressType');
    const contentAddressStreet = document.getElementsByClassName('contentAddressStreet');
    const contentAddressNumber = document.getElementsByClassName('contentAddressNumber');
    const contentAddressDistrict = document.getElementsByClassName('contentAddressDistrict');
    const contentAddressDetails = document.getElementsByClassName('contentAddressDetails');
    const contentAddressCepCityState = document.getElementsByClassName('contentAddressCep');

    for (let i = 0; i < contentAddressType.length; i += 1) {
      const addressType = stateAddressTypeOptions.filter(
        ({ label }) => label === contentAddressType[i].innerText
      )[0];

      const data = {
        Cd_collaborator: collaboratorId,
        It_subject_type: 1,
        It_subject: 1,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        St_zip_code: contentAddressCepCityState[i].innerText.split(' -')[0],
        Cd_address_type: addressType.value,
        St_state: contentAddressCepCityState[i].innerText.split(' -')[2].trim(),
        St_city: contentAddressCepCityState[i].innerText.split(' -')[1].trim(),
        St_district: contentAddressDistrict[i].innerText,
        St_street: contentAddressStreet[i].innerText.split(',')[0],
        It_number: contentAddressNumber[i].innerText,
        St_address_details: contentAddressDetails[i].innerText,
      };

      await AppointmentApi({
        method: 'POST',
        url: '/Address',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  async function insertDependents(collaboratorId) {
    const contentDependentName = document.getElementsByClassName('contentDependentName');
    const contentDependentCpf = document.getElementsByClassName('contentDependentCpf');
    const contentDependentBirthday = document.getElementsByClassName('contentDependentBirthday');
    const contentDependentRelationship = document.getElementsByClassName(
      'contentDependentRelationship'
    );
    const contentDependentIr = document.getElementsByClassName('contentDependentIr');

    for (let i = 0; i < contentDependentName.length; i += 1) {
      const dateString = contentDependentBirthday[i].innerText.split(':')[1].trim();
      const dateParts = dateString.split('/');
      const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      const isoDate = date.toISOString();

      const data = {
        Cd_collaborator: collaboratorId,
        Cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        St_name: contentDependentName[i].innerText,
        St_cpf: contentDependentCpf[i].innerText,
        Dt_birthday: isoDate,
        St_relationship: contentDependentRelationship[i].innerText.split(':')[1].trim(),
        Fl_dependent_ir: contentDependentIr[i].innerText.split('-')[1].trim() === 'Sim' ? 1 : 0,
      };

      try {
        const res = await AppointmentApi({
          method: 'POST',
          url: '/CollaboratorDependents',
          data,
        });
        return res;
      } catch (err) {
        toast.error(err.response?.data || 'Erro ao enviar os dados');
        throw err;
      }
    }
  }

  const clearFields = async () => {
    // dados cadastrais
    document.getElementById('nameField').value = '';
    document.getElementById('usernameField').value = '';
    document.getElementById('emailField').value = '';
    document.getElementById('cpfField').value = '';
    setStateBirthDate(undefined);
    setStateProfile(undefined);
    document.getElementById('passwordField').value = '';
    document.getElementById('socialNameField').value = '';
    document.getElementById('rgField').value = '';
    document.getElementById('issuingAgencyField').value = '';
    setStateIssuingState(undefined);
    document.getElementById('pisField').value = '';
    document.getElementById('fatherNameField').value = '';
    document.getElementById('motherNameField').value = '';
    setStateCountry(undefined);
    setStateHomeState(undefined);
    setStateHometown(undefined);
    setStateMaritalStatus(undefined);
    setStateEducationGrade(undefined);
    setStateSkinColor(undefined);
    setStateSex(undefined);
    setStateRegisterDate(undefined);
    setStateShutdownDate(undefined);
    document.getElementById('timeStart').value = '';
    document.getElementById('timeEnd').value = '';
    document.getElementById('timeBreak').value = '';
    document.getElementById('valuationHoursField').value = '';
    setStateWorkRegime(undefined);
    setStateClient(undefined);
    setStateJobRole(undefined);
    setStateAccountType(undefined);
    setStateBank(undefined);
    document.getElementById('bankAgencyField').value = '';
    document.getElementById('bankAgencyDigitField').value = '';
    document.getElementById('bankAccountField').value = '';
    document.getElementById('bankAccountDigitField').value = '';

    // Address
    addressesCardContent = [];
    document.getElementById('cepField').value = '';
    document.getElementById('districtField').value = '';
    document.getElementById('streetField').value = '';
    document.getElementById('numberField').value = '';
    document.getElementById('addressDetailsField').value = '';
    setLoadingAddress(true);
    setTypeOfAddress2(null);
    setValueAddressCity2(null);
    await setStateOfAddress(undefined);
    await setValueStreet('');
    await setValueNumber('');
    await setValueDistrict('');
    await setValueCEP('');
    await setValueComplement('');
    await setIsEditAddress(false);
    setLoadingAddress(false);

    // Contacts
    contactsCardContent = [];
    setLoadingContact(true);
    document.getElementById('descriptionField').value = '';
    await setIsEditContacts(false);
    setLoadingContact(false);

    // Dependent
    dependenetsCardContent = [];
    document.getElementById('dependentNameField').value = '';
    document.getElementById('dependentCpfField').value = '';
    document.getElementById('dependentIrCheckbox').checked = false;
    setLoadingContent(true);
    setValueIncomeTax(false);
    setIsEditDependent(false);
    await setValueName('');
    await setValueCpf('');
    await setValueDate(undefined);
    await setValueRelations(undefined);
    setLoadingContent(false);
  };

  async function CollaboratorInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const data = {
        userEntity: {
          st_name: document.getElementById('nameField').value,
          st_login: document.getElementById('usernameField').value,
          st_email: document.getElementById('emailField').value,
          st_cpf: document.getElementById('cpfField').value,
          dt_birthday: stateBirthDate,
          st_profile: stateProfile.label,
          cd_profile: stateProfile.value,
          st_password: document.getElementById('passwordField').value,
          cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        },
        collaboratorEntity: {
          st_rg_number: document.getElementById('rgField').value,
          st_social_name: document.getElementById('socialNameField').value,
          st_rg_organ: document.getElementById('issuingAgencyField').value,
          st_rg_state: stateIssuingState,
          st_pis_number: document.getElementById('pisField').value,
          st_father_name: document.getElementById('fatherNameField').value,
          st_mother_name: document.getElementById('motherNameField').value,
          st_birth_country: stateValueCountry,
          st_birth_state: stateHomeState,
          st_birth_city: stateHometown,
          st_marital_status: stateMaritalStatus,
          st_education_grade: stateEducationGrade,
          st_skin_color: stateSkinColor,
          st_sex: stateSex,
          dt_register: stateRegisterDate,
          dt_shutdown: stateShutdownDate,
          ti_time_entry: document.getElementById('timeStart').value,
          ti_time_exit: document.getElementById('timeEnd').value,
          ti_time_break: document.getElementById('timeBreak').value,
          dc_hour_value: document.getElementById('valuationHoursField').value,
          cd_work_regime: stateWorkRegime.value,
          cd_juridical_person: stateClient.value,
          cd_job_role: stateJobRole.value,
          st_bank_type: stateAccountType,
          st_bank: parseInt(stateBank.value, 10),
          st_bank_agency: document.getElementById('bankAgencyField').value,
          st_bank_agency_digit: document.getElementById('bankAgencyDigitField').value,
          st_bank_account: document.getElementById('bankAccountField').value,
          st_bank_account_digit: document.getElementById('bankAccountDigitField').value,
          cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        },
      };

      if (isEdit) {
        Object.assign(data, {
          cd_collaborator: parseInt(id, 10),
        });
      }

      try {
        const res = await AppointmentApi({
          method: isEdit ? 'PUT' : 'POST',
          url: '/Collaborators',
          data,
        });

        if (res.status === 200 || res.status === 201) {
          if (!isEdit) {
            await insertContacts(res.data);
            await insertAddress(res.data);
            await insertDependents(res.data);
          }

          toast.success('Cadastro reaalizado com sucesso!');

          if (redirect) {
            navigate('/Apontamento/Colaboradores');
          } else {
            scrollTop();
            clearFields();
          }

          if (isEdit) {
            document.getElementById('delete').disabled = false;
          } else {
            document.getElementById('saveAndNew').disabled = false;
          }
          document.getElementById('save').disabled = false;
        }
      } catch (err) {
        toast.error(err.response?.data || 'Erro ao realizar o cadastro.');

        if (isEdit) {
          document.getElementById('delete').disabled = false;
        } else {
          document.getElementById('saveAndNew').disabled = false;
        }
        document.getElementById('save').disabled = false;

      }
    }
  }

  function fillSelects() {
    setStateProfileOptions(profileOptions);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (optionsFilled === false) {
        await fillProfileOptions();
        await fillClientOptions();
        await fillJobRoleOptions();
        await fillContactTypeOptions();
        await fillAddressTypeOptions();
        fillSelects();
        handleNavigation();
      }
      if (isEdit) {
        getCollaboratorById();
        getContactsById();
        getAddressById();
        getDependentsById();
      }
    };
    fetchData();
  }, [optionsFilled]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    Inputmask({ mask: '99999-999', placeholder: '99999-999' }).mask(
      document.getElementById('cepField')
    );
    // eslint-disable-next-line no-undef
    Inputmask({ mask: '999.999.999-99' }).mask(document.getElementById('cpfField'));
  }, []);

  useEffect(() => {
    if (document.getElementById('descriptionField')) {
      document.getElementById('descriptionField').value = valueDescription;
    }
  }, [valueDescription]);

  useEffect(() => {
    if (document.getElementById('cepField')) {
      // eslint-disable-next-line no-undef
      Inputmask({ mask: '99999-999' }).mask(document.getElementById('cepField'));

      document.getElementById('cepField').value = valueCEP;
      document.getElementById('districtField').value = valueDistrict;
      document.getElementById('streetField').value = valueStreet;
      document.getElementById('numberField').value = valueNumber;
      document.getElementById('addressDetailsField').value = valueComplement;
    }
  }, [loadingAddress]);

  useEffect(() => {
    if (document.getElementById('dependentNameField')) {
      // eslint-disable-next-line no-undef
      Inputmask({ mask: '999.999.999-99' }).mask(document.getElementById('dependentCpfField'));

      document.getElementById('dependentNameField').value = valueName;
      document.getElementById('dependentCpfField').value = valueCpf;

      setValueRelations(stateRelations);
      setValueDate(moment(valueDate, 'DD/MM/YYYY'));

      // if (isEditDependent) {
      //   const cards = document.getElementsByClassName('card content-card');
      //   cards[cardIdPag].classList.add('content-card__selected');
      // }
      if (valueIncomeTax) {
        document.getElementById('dependentIrCheckbox').checked = true;
      } else {
        document.getElementById('dependentIrCheckbox').checked = false;
      }
    }
  }, [loadingContent]);

  return (
    <>
    <ToastContainer />
      <div className="page-content collaborators-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Apontamento/Colaboradores">Colaboradores</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Registro de Colaboradores</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="nameField"
                >
                  Nome completo
                  <input id="nameField" className="mt-2" type="text" />
                </label>
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="usernameField"
                >
                  Login
                  <input id="usernameField" className="mt-2" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="emailField"
                >
                  E-mail
                  <input
                    id="emailField"
                    className="mt-2"
                    type="text"
                    placeholder="user@email.com"
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="cpfField"
                >
                  CPF
                  <input id="cpfField" className="mt-2" type="text" placeholder="000.000.000-00" />
                </label>
              </div>

              {/* Row 2 */}
              <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="text-color-light form-label">Data de Nascimento</label>
                  <DatePicker
                    placeholder="Selecionar Data"
                    value={stateBirthDate}
                    onChange={(e) => {
                      if (e) {
                        setStateBirthDate(e);
                      } else {
                        setStateBirthDate(undefined);
                      }
                    }}
                    format="DD/MM/YYYY"
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        today: 'Hoje',
                      },
                    }}
                  />
                </div>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Perfil
                  <SelectAntd
                    id="profileField"
                    value={stateProfile}
                    options={stateProfileOptions}
                    className="select-container mt-2"
                    classNamePrefix="select"
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    onChange={(e, value) => {
                      if (value) {
                        setStateProfile(value);
                      } else {
                        setStateProfile(undefined);
                      }
                    }}
                  />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="passwordField"
                >
                  Senha
                  <div className="input-group pswd-eye">
                    <input id="passwordField" type="password" className="form-control mt-2" />
                    <span
                      id="pswdEye"
                      className="input-group-text mt-2"
                      role="button"
                      tabIndex="0"
                      onClick={toggleEye}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          toggleEye();
                        }
                      }}
                    >
                      <FaEye className="normal-eye" />
                      <FaEyeSlash className="slashed-eye" />
                    </span>
                  </div>
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                  htmlFor="confirmPasswordField"
                >
                  Confirmação Senha
                  <div className="input-group pswd-eye">
                    <input
                      id="confirmPasswordField"
                      type="password"
                      className="form-control mt-2"
                    />
                    <span
                      id="pswdEye2"
                      className="input-group-text mt-2"
                      onClick={toggleEye2}
                      role="button"
                      tabIndex="0"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          toggleEye2();
                        }
                      }}
                    >
                      <FaEye className="normal-eye" />
                      <FaEyeSlash className="slashed-eye" />
                    </span>
                  </div>
                </label>
              </div>

              <CompCollapsible
                title="Dados Pessoais"
                content={
                  <>
                    {/* Row 1 */}
                    <div className="row">
                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="socialNameField"
                      >
                        Nome Social
                        <input id="socialNameField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="rgField"
                      >
                        RG
                        <input id="rgField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                        htmlFor="issuingAgencyField"
                      >
                        Órgão Emissor
                        <input id="issuingAgencyField" className="mt-2" type="text" />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Estado Emissor
                        <SelectAntd
                          value={stateIssuingState}
                          options={stateOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateIssuingState(e);
                            } else {
                              setStateIssuingState(undefined);
                            }
                          }}
                        />
                      </label>
                    </div>

                    {/* Row 5 */}
                    <div className="row mt-4">
                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="pisField"
                      >
                        PIS/PASEP
                        <input id="pisField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="fatherNameField"
                      >
                        Nome do Pai
                        <input id="fatherNameField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="motherNameField"
                      >
                        Nome da Mãe
                        <input id="motherNameField" className="mt-2" type="text" />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        País
                        <SelectAntd
                          value={stateValueCountry}
                          options={countryOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateCountry(e);
                            } else {
                              setStateCountry(undefined);
                            }
                          }}
                        />
                      </label>
                    </div>

                    {/* Row 6 */}
                    <div className="row mt-4">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        Estado Natal
                        <SelectAntd
                          value={stateHomeState}
                          options={stateOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              fulfillCitySelect(e, 1);
                              setStateHomeState(e);
                            } else {
                              setStateHomeState(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        Cidade Natal
                        <SelectAntd
                          value={stateHometown}
                          options={stateCityOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateHometown(e);
                            } else {
                              setStateHometown(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        Estado Civil
                        <SelectAntd
                          value={stateMaritalStatus}
                          options={StatusMaritalOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateMaritalStatus(e);
                            } else {
                              setStateMaritalStatus(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        Escolaridade
                        <SelectAntd
                          value={stateEducationGrade}
                          options={EducationGradeOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateEducationGrade(e);
                            } else {
                              setStateEducationGrade(undefined);
                            }
                          }}
                        />
                      </label>
                    </div>

                    {/* Row 8 */}
                    <div className="row mt-4">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Cor da Pele
                        <SelectAntd
                          value={stateSkinColor}
                          options={SkinColorOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateSkinColor(e);
                            } else {
                              setStateSkinColor(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Sexo
                        <SelectAntd
                          value={stateSex}
                          options={SexOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateSex(e);
                            } else {
                              setStateSex(undefined);
                            }
                          }}
                        />
                      </label>
                    </div>
                  </>
                }
              />

              <CompCollapsible
                title="Dados Administrativos"
                content={
                  <>
                    {/* Row 8 */}
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="text-color-light form-label">Data de Registro</label>
                        <DatePicker
                          id="registerDate"
                          placeholder="Selecionar Data"
                          value={stateRegisterDate}
                          defaultValue={undefined}
                          onChange={(e) => {
                            if (e) {
                              setStateRegisterDate(e);
                            } else {
                              setStateRegisterDate(undefined);
                            }
                          }}
                          format={dateFormat}
                          locale={{
                            ...locale,
                            lang: {
                              ...locale.lang,
                              today: 'Hoje',
                            },
                          }}
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="text-color-light form-label">Data de Desligamento</label>
                        <DatePicker
                          id="shutdownDate"
                          placeholder="Selecionar Data"
                          value={stateShutdownDate}
                          onChange={(e) => {
                            if (e) {
                              setStateShutdownDate(e);
                            } else {
                              setStateShutdownDate(undefined);
                            }
                          }}
                          format={dateFormat}
                          locale={{
                            ...locale,
                            lang: {
                              ...locale.lang,
                            },
                          }}
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="text-color-light form-label">Hora de Entrada</label>
                        <TimePicker
                          value={stateStartTime}
                          id="timeStart"
                          allowClear
                          placeholder="Selecione..."
                          onSelect={(time) => {
                            setStateStartTime(time);
                          }}
                          onChange={(e) => {
                            if (!e) {
                              setStateStartTime(null);
                            }
                          }}
                          defaultOpenValue={moment('8:00', 'HH:mm')}
                          format="HH:mm"
                          showNow={false}
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="text-color-light form-label">Hora De Saída</label>
                        <TimePicker
                          value={stateEndTime}
                          id="timeEnd"
                          allowClear
                          placeholder="Selecione..."
                          onSelect={(time) => {
                            setStateEndTime(time);
                          }}
                          onChange={(e) => {
                            if (!e) {
                              setStateEndTime(null);
                            }
                          }}
                          format="HH:mm"
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="text-color-light form-label">Tempo de Pausa</label>
                        <TimePicker
                          value={statePauseTime}
                          id="timeBreak"
                          allowClear
                          placeholder="Selecione..."
                          onSelect={(time) => {
                            setStatePauseTime(time);
                          }}
                          onChange={(e) => {
                            if (!e) {
                              setStatePauseTime(null);
                            }
                          }}
                          format="HH:mm"
                        />
                      </div>
                    </div>

                    {/* Row 8 */}
                    <div className="row mt-4">
                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                        htmlFor="valuationHoursField"
                      >
                        Valor Hora
                        <input id="valuationHoursField" className="mt-2" type="text" />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Regime de Trabalho
                        <SelectAntd
                          id="workRegime"
                          value={stateWorkRegime}
                          options={WorkRegimeOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e, value) => {
                            if (value) {
                              setStateWorkRegime(value);
                            } else {
                              setStateWorkRegime(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Cliente
                        <SelectAntd
                          id="client"
                          value={stateClient}
                          options={stateClientOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e, value) => {
                            if (value) {
                              setStateClient(value);
                            } else {
                              setStateClient(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        Cargo
                        <SelectAntd
                          id="jobRole"
                          value={stateJobRole}
                          options={stateJobRoleOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e, value) => {
                            if (value) {
                              setStateJobRole(value);
                            } else {
                              setStateJobRole(undefined);
                            }
                          }}
                        />
                      </label>
                    </div>
                  </>
                }
              />

              <CompCollapsible
                title="Dados Bancários"
                content={
                  <>
                    {/* Row 10 */}
                    <div className="row">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Tipo de Conta
                        <SelectAntd
                          id="accountType"
                          value={stateAccountType}
                          options={AccountTypeOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e) => {
                            if (e) {
                              setStateAccountType(e);
                            } else {
                              setStateAccountType(undefined);
                            }
                          }}
                        />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        Banco
                        <SelectAntd
                          name="bankField"
                          value={stateBank}
                          options={bankOptions}
                          className="select-container mt-2"
                          classNamePrefix="select"
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          onChange={(e, value) => {
                            if (value) {
                              setStateBank(value);
                            } else {
                              setStateBank(undefined);
                            }
                          }}
                        />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2"
                        htmlFor="bankAgencyField"
                      >
                        Agência
                        <input id="bankAgencyField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-1 col-xl-1"
                        htmlFor="bankAgencyDigitField"
                      >
                        Dígito
                        <input id="bankAgencyDigitField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2"
                        htmlFor="bankAccountField"
                      >
                        Conta
                        <input id="bankAccountField" className="mt-2" type="text" />
                      </label>

                      <label
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-1 col-xl-1"
                        htmlFor="bankAccountDigitField"
                      >
                        Dígito
                        <input id="bankAccountDigitField" className="mt-2" type="text" />
                      </label>
                    </div>
                  </>
                }
              />
              {loadingContact ? (
                <CompCollapsible title="Carregando..." />
              ) : (
                <CompCollapsible
                  title="Contato"
                  content={
                    <div className="row">
                      <DynamicCard
                        rightCardTitle="Contatos"
                        cardName="contact"
                        funcAddNew={newContactCard}
                        funcUpdtState={updateContactsState}
                        funcFillFields={fillContactFields}
                        isEdit={isEditContact}
                        funcEditCard={editContactCard}
                        funcClearFields={clearContactFields}
                        rightCardContent={contactsCardContent}
                        fields={
                          <>
                            <div className="row">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                Tipo de Contato
                                <Select
                                  options={stateContactTypeOptions}
                                  ref={(ref) => {
                                    if (ref) {
                                      contactRef = ref;
                                    }
                                  }}
                                  closeMenuOnSelect
                                  hideSelectedOptions={false}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  isSearchable
                                  components={{ MenuList, ValueContainer }}
                                  placeholder="Selecione..."
                                  noOptionsMessage={() => 'Nenhum registro encontrado'}
                                  styles={selectStyle}
                                />
                              </label>
                            </div>

                            <div className="row mt-4">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                htmlFor="descriptionField"
                              >
                                Descrição
                                <input id="descriptionField" className="mt-2" type="text" />
                              </label>
                            </div>
                          </>
                        }
                      />
                    </div>
                  }
                />
              )}

              {loadingAddress ? (
                <CompCollapsible title="Carregando..." />
              ) : (
                <CompCollapsible
                  title="Endereço"
                  content={
                    <div className="row">
                      <DynamicCard
                        rightCardTitle="Endereços"
                        cardName="address"
                        funcAddNew={newAddressCard}
                        funcUpdtState={updateAddressesState}
                        funcFillFields={fillAddressFields}
                        isEdit={isEditAddress}
                        funcEditCard={editAddressCard}
                        funcClearFields={clearAddressFields}
                        rightCardContent={isEdit ? addressesCardContent : AddressesCardContent}
                        fields={
                          <>
                            <div className="row">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Tipo de Endereço
                                <SelectAntd
                                  options={stateAddressTypeOptions}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  value={typeOfAddress2}
                                  onChange={async (e, value) => {
                                    if (e !== undefined) {
                                      typeOfAddress = value.label;
                                      setLoadingAddress(true);
                                      await setTypeOfAddress2(value.label);
                                      setLoadingAddress(false);
                                    } else {
                                      typeOfAddress = null;
                                      setLoadingAddress(true);
                                      await setTypeOfAddress2(null);
                                      setLoadingAddress(false);
                                    }
                                  }}
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="cepField"
                              >
                                CEP
                                <input
                                  id="cepField"
                                  className="mt-2"
                                  type="text"
                                  placeholder="00000-000"
                                  maxLength="9"
                                  onKeyUp={(e) => {
                                    setValueCEP(e.target.value);
                                    getAddressByCep(e.target.value);
                                    if (e.key === 'Backspace') {
                                      document.getElementById('streetField').value = '';
                                      document.getElementById('districtField').value = '';
                                    }
                                  }}
                                />
                              </label>
                            </div>

                            <div className="row mt-3">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Estado
                                <SelectAntd
                                  value={stateOfAddress}
                                  options={stateOptions}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  showSearch
                                  allowClear
                                  optionFilterProp="label"
                                  name="addressState"
                                  onKeyUp={(e) => {
                                    setStateOfAddress(e.target.value);
                                  }}
                                  onChange={async (value, e) => {
                                    if (e && e !== undefined) {
                                      fulfillCitySelect(e.value, 2);
                                      setStateOfAddress(e);
                                      setLoadingAddress(true);
                                      await setValueAddressCity2(null);
                                      setLoadingAddress(false);
                                    } else {
                                      setLoadingAddress(true);
                                      await setStateOfAddress(null);
                                      await setStateAddressCityOptions([]);
                                      await setValueAddressCity2(null);
                                      setLoadingAddress(false);
                                    }
                                  }}
                                  components={{ MenuList, ValueContainer }}
                                />
                              </label>

                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Cidade
                                {stateAddressCityOptions && (
                                  <SelectAntd
                                    value={valueAddressCity2}
                                    options={stateAddressCityOptions}
                                    className="select-container mt-2"
                                    classNamePrefix="select"
                                    showSearch
                                    allowClear
                                    optionFilterProp="label"
                                    name="addressCity"
                                    placeholder="Selecione..."
                                    onChange={async (value, e) => {
                                      if (e !== undefined) {
                                        valueAddressCity = e;
                                        setLoadingAddress(true);
                                        await setValueAddressCity2(e);
                                        setLoadingAddress(false);
                                      } else {
                                        valueAddressCity = null;
                                        setLoadingAddress(true);
                                        await setValueAddressCity2(null);
                                        setLoadingAddress(false);
                                      }
                                    }}
                                  />
                                )}
                                {!stateAddressCityOptions && (
                                  <SelectAntd
                                    className="select-container mt-2"
                                    classNamePrefix="select"
                                    loading
                                  />
                                )}
                              </label>
                            </div>

                            <div className="row mt-3">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="districtField"
                              >
                                Bairro
                                <input
                                  onKeyUp={(e) => {
                                    setValueDistrict(e.target.value);
                                  }}
                                  id="districtField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="streetField"
                              >
                                Rua
                                <input
                                  onKeyUp={(e) => {
                                    setValueStreet(e.target.value);
                                  }}
                                  id="streetField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>
                            </div>

                            <div className="row mt-3">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="numberField"
                              >
                                Número
                                <input
                                  onKeyUp={(e) => {
                                    setValueNumber(e.target.value);
                                  }}
                                  id="numberField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="addressDetailsField"
                              >
                                Complemento
                                <input
                                  onKeyUp={(e) => {
                                    setValueComplement(e.target.value);
                                  }}
                                  id="addressDetailsField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>
                            </div>
                          </>
                        }
                      />
                    </div>
                  }
                />
              )}

              {loadingContent ? (
                <CompCollapsible title="Carregando..." />
              ) : (
                <CompCollapsible
                  title="Dependentes"
                  content={
                    <div className="row">
                      <DynamicCard
                        rightCardTitle="Dependentes"
                        cardName="dependent"
                        funcAddNew={newDependentCard}
                        funcUpdtState={updateDependentsState}
                        funcFillFields={fillDependentFields}
                        isEdit={isEditDependent}
                        funcEditCard={editDependentCard}
                        funcClearFields={clearDependentFields}
                        rightCardContent={isEdit ? dependenetsCardContent : dependentsCardContent}
                        fields={
                          <>
                            <div className="row">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="dependentNameField"
                              >
                                Nome
                                <input
                                  onKeyUp={(e) => {
                                    setValueName(e.target.value);
                                  }}
                                  id="dependentNameField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="dependentCpfField"
                              >
                                CPF
                                <input
                                  onKeyUp={(e) => {
                                    setValueCpf(e.target.value);
                                  }}
                                  id="dependentCpfField"
                                  className="mt-2"
                                  type="text"
                                  placeholder="000.000.000-00"
                                />
                              </label>
                            </div>

                            <div className="row mt-4">
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="text-color-light form-label">
                                  Data de Nascimento
                                </label>
                                <DatePicker
                                  id="birthDate"
                                  placeholder="Selecionar Data"
                                  value={valueDate}
                                  onChange={async (e) => {
                                    setLoadingContent(true);
                                    if (e) {
                                      await setValueDate(moment(e, 'DD/MM/YYYY'));
                                    } else {
                                      await setValueDate('');
                                    }
                                    setLoadingContent(false);
                                  }}
                                  format="DD/MM/YYYY"
                                  locale={{
                                    ...locale,
                                    lang: {
                                      ...locale.lang,
                                      today: 'Hoje',
                                    },
                                  }}
                                />
                              </div>

                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Parentesco
                                <SelectAntd
                                  options={relationshipOptions}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  showSearch
                                  allowClear
                                  value={stateRelations}
                                  optionFilterProp="label"
                                  onChange={async (e) => {
                                    if (e !== undefined) {
                                      setLoadingContent(true);
                                      await setValueRelations(e);
                                      setLoadingContent(false);
                                    } else {
                                      setLoadingContent(true);
                                      await setValueRelations(undefined);
                                      setLoadingContent(false);
                                    }
                                  }}
                                />
                              </label>
                            </div>

                            <div className="row mt-4">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-check">
                                  <label
                                    className="form-check-label me-3"
                                    htmlFor="dependentIrCheckbox"
                                  >
                                    <input
                                      className="form-check-input me-3"
                                      type="checkbox"
                                      id="dependentIrCheckbox"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setValueIncomeTax(true);
                                        } else {
                                          setValueIncomeTax(false);
                                        }
                                      }}
                                    />
                                    Imposto de Renda
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </div>
                  }
                />
              )}

              {/* Row 11 */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      CollaboratorInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        CollaboratorInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    CollaboratorInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      CollaboratorInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest({ cd_collaborator: id }, 'Collaborator');
                    navigate('/Apontamento/Colaborador');
                  }}
                >
                  Sim
                </button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
