/* eslint-disable no-const-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Select as SelectAntd, Breadcrumb } from 'antd';
import Select from 'react-select';
import { parse } from 'date-fns';
import DynamicCard from '../../../../Admin/components/DynamicCards';
import statesCities from '../../../../Website/assets/scripts/statesCities.json';
import CompCollapsible from '../../../../Admin/components/Collapsible';
import { excludeRequest, scrollTop } from '../../../../Admin/assets/scripts/global';
import { AppointmentApi } from '../../../../../services/api';
import selectStyle from '../../../../Admin/components/Shared/selectStyle';
import { MenuList, ValueContainer } from '../../../../Admin/components/Shared/selectComponents';
import './style.scss';

const contactOptions = [];
const addressOptions = [];
const addressesCardContent = [];
let adressTypeOptions = [];
let optionsFilled = false;
let stateOptions = [];
let cityOptions = [];
let contactRef;
let typeOfAddress;
let valueAddressCity;
let contactTypeOptions = [];
let juridicalPersonOptions;

export default function JuridicalPersonRegister() {
  const [stateCityOptions, setStateCityOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.split('=').length > 1;
  const id = isEdit ? location.search.split('=')[1] : null;
  const [AddressesCardContent, setAddressesCardContent] = useState([]);
  const [contactsCardContent, setContactsCardContent] = useState([]);
  const [stateContactOptions, setStateContactOptions] = useState([]);
  const [stateAddressOptions, setStateAddressOptions] = useState([]);
  const [stateAddressTypeOptions, setStateAdressTypeOptions] = useState([]);
  const [stateContactTypeOptions, setStateContactTypeOptions] = useState([]);
  const [stateAddressCityOptions, setStateAddressCityOptions] = useState();
  const [cardIdPag, setCardId] = useState('');
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditContact, setIsEditContacts] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [typeOfAddress2, setTypeOfAddress2] = useState();
  const [valueDistrict, setValueDistrict] = useState('');
  const [valueNumber, setValueNumber] = useState('');
  const [valueCEP, setValueCEP] = useState('');
  const [valueStreet, setValueStreet] = useState('');
  const [valueComplement, setValueComplement] = useState('');
  const [valueAddressCity2, setValueAddressCity2] = useState();
  const [stateOfAddress, setStateOfAddress] = useState();
  const [valuePersonType, setvaluePersonType] = useState();
  const [statePersonType, setStatePersonType] = useState([]);
  const [statePersonTypeOptions, setStatePersonTypeOptions] = useState([]);

  navigate((location) => {
    if (
      location.pathname + location.search.split('=')[0] !==
      '/Recrutamento/Vagas/Registro?cd_juridical_person'
    ) {
      optionsFilled = false;
    }
  });

  async function fillPersonTypeOptions() {
    await AppointmentApi.get('/JuridicalPersons/GetJuridicalType')
      .then((response) => {
        setStatePersonTypeOptions(response.data);
      })
      .catch((err) => err);
  }

  async function fillAddressTypeOptions() {
    setLoadingAddress(true);
    await AppointmentApi.get('/AddressTypes/GetTypeAddress')
      .then((response) => {
        setStateAdressTypeOptions(response.data);
        adressTypeOptions = response.data;
      })
      .catch((err) => err);
    setLoadingAddress(false);
  }

  async function fillContactTypeOptions() {
    setLoadingContact(true);
    await AppointmentApi.get('/ContactTypes/GetTypeContact')
      .then((response) => {
        setStateContactTypeOptions(response.data);
        contactTypeOptions = response.data;
      })
      .catch((err) => err);
    setLoadingContact(false);
  }

  stateOptions = [];
  statesCities.states.forEach((state) => {
    stateOptions.push({ label: state.name, value: state.acronym });
  });
  cityOptions = [];

  async function fulfillCitySelect(acronym, wich, cardId) {
    cityOptions.length = 0;

    statesCities.states.forEach(async (state) => {
      if (state.acronym === acronym) {
        state.citys.forEach((city) => {
          cityOptions.push({ label: city, value: city });
        });
        if (wich === 1) {
          setStateCityOptions(cityOptions);
        } else {
          setLoadingAddress(true);
          await setStateAddressCityOptions(cityOptions);
          setLoadingAddress(false);
          if (cardId) {
            state.citys.forEach((city) => {
              cityOptions.push({ label: city, value: city });
            });
            const valueCity = document
              .getElementsByClassName('contentAddressCep')
              [cardId].innerText.split('-')[2];

            const valueCardRightCity = cityOptions.filter((obj) => obj.label === valueCity.trim());
            setLoadingAddress(true);
            await setValueAddressCity2(
              valueCardRightCity.length > 0 ? valueCardRightCity[0].value : undefined
            );
            setLoadingAddress(false);
            const cards = document.getElementsByClassName('card content-card');
            cards[cardId].classList.add('content-card__selected');
          }
        }
      }
    });
  }

  // Limpa campos Contact
  const clearContactFields = () => {
    document.getElementById('descriptionField').value = '';
    setLoadingContact(true);
    setIsEditContacts(false);
    setLoadingContact(false);
  };

  // Função para atualizar o state contactsCardContent
  const updateContactsState = () => {
    setContactsCardContent(contactsCardContent);
  };

  // Função para adicionar contato com dados da requisição
  const newContactCardEditReq = async (data) => {
    setContactsCardContent('a');
    const description = data.st_content;
    const typeFilter = contactTypeOptions.filter(({ value }) => value === data.cd_contact_type);
    const type = typeFilter.length > 0 ? typeFilter[0].label : undefined;

    if (description !== '' && type !== undefined) {
      // Monta o HTML que vai dentro dos cards
      const contactDescription = React.createElement(
        'p',
        { className: 'contentContactDescription' },
        description
      );
      const contactType = React.createElement('p', { className: 'contentContactType' }, type);
      const contactCard = React.createElement('div', { className: 'contact-card' }, [
        contactType,
        contactDescription,
      ]);

      // Adiciona o HTML no state
      setContactsCardContent(
        contactsCardContent.push({
          content: contactCard,
        })
      );
    }
  };
  // Função para preencher os campos de contato
  const fillContactFields = (cardId, currentId) => {
    setLoadingContact(true);
    const inputCurrentId = document.getElementById(currentId);
    const contentContactDescription = document.getElementsByClassName('contentContactDescription')[
      cardId
    ].innerText;
    const valueTypeContact =
      document.getElementsByClassName('contentContactType')[cardId].innerText;
    contactRef.select.setValue(
      stateContactTypeOptions.filter((obj) => obj.label === valueTypeContact)
    );
    inputCurrentId.value = cardId;
    document.getElementById('descriptionField').value = contentContactDescription;
    setLoadingContact(false);
  };

  async function insertContacts(juridicalPersonId) {
    const contentContactDescription = document.getElementsByClassName('contentContactDescription');
    const contentContactType = document.getElementsByClassName('contentContactType');

    for (let i = 0; i < contentContactDescription.length; i += 1) {
      const contactType = stateContactTypeOptions.filter(
        ({ label }) => label === contentContactType[i].innerText
      )[0];
      const data = {
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        Cd_juridical_person: juridicalPersonId,
        St_content: contentContactDescription[i].innerText,
        Cd_contact_type: contactType.value,
        It_subject_type: 1,
        It_subject: 1,
      };
      await AppointmentApi({
        method: 'POST',
        url: '/Contacts',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  // Função para editar os campos de contato
  async function editContactCard(cardIndex) {
    const cdContact = document.getElementsByClassName('cdContact')[cardIndex];
    const contentContactDescription = document.getElementsByClassName('contentContactDescription')[
      cardIndex
    ];
    const contentContactType = document.getElementsByClassName('contentContactType');

    const valueTypeContact = document.getElementsByClassName('contentContactType')[cardIndex];
    valueTypeContact.innerText = contactRef.select.getValue()[0].label;
    contentContactDescription.innerText = document.getElementById('descriptionField').value;

    if (isEdit) {
      const data = {
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        st_content: contentContactDescription.innerText,
        cd_contact_type: contactRef.select.getValue()[0].value,
        cd_contact: parseInt(cdContact.innerText, 10),
      };

      await AppointmentApi({
        method: 'PUT',
        url: '/Contacts',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  // Função para adicionar contato
  const newContactCard = () => {
    setContactsCardContent('a');
    const description = document.getElementById('descriptionField');
    const type = contactRef.select.getValue()[0].label;

    if (description.value !== '' && type !== undefined) {
      // Monta o HTML que vai dentro dos cards
      const contactDescription = React.createElement(
        'p',
        { className: 'contentContactDescription' },
        description.value
      );
      const contactType = React.createElement('p', { className: 'contentContactType' }, type);
      const contactCard = React.createElement('div', { className: 'contact-card' }, [
        contactType,
        contactDescription,
      ]);

      // Adiciona o HTML no state
      setContactsCardContent(
        contactsCardContent.push({
          content: contactCard,
        })
      );

      // Limpa os campos
      clearContactFields();
    }
  };
  // Limpa campos Address
  const clearAddressFields = async () => {
    if (document.getElementById('cepField')) {
      document.getElementById('cepField').value = '';
      document.getElementById('districtField').value = '';
      document.getElementById('streetField').value = '';
      document.getElementById('numberField').value = '';
      document.getElementById('addressDetailsField').value = '';
      setLoadingAddress(true);
      setTypeOfAddress2(null);
      setValueAddressCity2(null);
      setStateOfAddress(undefined);
      setValueNumber('');
      setValueDistrict('');
      setValueCEP('');
      setValueComplement('');
      setIsEditAddress(false);
      setLoadingAddress(false);
    }
  };
  // Função para adicionar endereço
  const newAddressCard = async () => {
    const type = typeOfAddress2;
    const cep = document.getElementById('cepField');
    const district = document.getElementById('districtField');
    const street = document.getElementById('streetField');
    const number = document.getElementById('numberField');
    const details = document.getElementById('addressDetailsField');
    const state = stateOfAddress;
    const city = valueAddressCity2;

    if (cep.value.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);
      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number.value
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street.value}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district.value
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details.value
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep.value} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);

      addressesCardContent.push({ content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);
      // Limpa os campos
      clearAddressFields();
    }
  };
  // Função para editar endereço com dados da requisição
  const newAddressCardEditReq = async (data) => {
    setLoadingAddress(true);

    const typeFilter = adressTypeOptions.filter(({ value }) => value === data.cd_address_type);
    const type = typeFilter.length > 0 ? typeFilter[0].label : undefined;
    const cep = data.st_zip_code;
    const district = data.st_district;
    const street = data.st_street;
    const number = data.it_number;
    const details = data.st_address_details;
    const state = data.st_state;
    const city = data.st_city;

    if (cep.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);

      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);

      addressesCardContent.push({ content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);

      setLoadingAddress(false);
    }
  };

  // Função para atualizar o state addressesCardContent
  const updateAddressesState = () => {
    setAddressesCardContent(AddressesCardContent);
  };

  // Fill address fields by CEP
  async function getAddressByCep(cep) {
    if (cep.length > 8) {
      await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (!res.erro) {
            document.getElementById('streetField').value = res.logradouro;
            document.getElementById('districtField').value = res.bairro;
            await fulfillCitySelect(res.uf, 2);
            setLoadingAddress(true);
            await setStateOfAddress(res.uf);
            await setValueAddressCity2(res.localidade);
            await setValueDistrict(res.bairro);
            await setValueStreet(res.logradouro);
            setLoadingAddress(false);
          } else {
            const formResponse = document.getElementById('response');
            formResponse.classList.remove('alert-success');
            formResponse.classList.add('alert-danger');
            formResponse.innerText = 'CEP não encontrado!';
            formResponse.style.display = 'block';
            setTimeout(() => {
              formResponse.style.display = 'none';
            }, 4000);
          }
        })
        .catch((err) => err);
    }
  }
  // Função para preencher os campos de endereço
  function fillAddressFields(cardId, currentId) {
    setCardId(cardId);
    setIsEditAddress(true);
    const inputCurrentId = document.getElementById(currentId);
    const valueTypeAddress =
      document.getElementsByClassName('contentAddressType')[cardId].innerText;

    setTypeOfAddress2(valueTypeAddress);

    const valueState = document
      .getElementsByClassName('contentAddressCep')
      [cardId].innerText.split('-')[3];

    const teste = stateOptions.filter((obj) => obj.value === valueState.trim());
    setStateOfAddress(teste.length > 0 ? teste[0] : undefined);
    fulfillCitySelect(teste.length > 0 ? teste[0].value : undefined, 2, cardId);

    const contentAddressCep = document
      .getElementsByClassName('contentAddressCep')
      [cardId].firstChild.data.split(' ')[0];

    const contentAddressNumber =
      document.getElementsByClassName('contentAddressNumber')[cardId].innerText;
    const contentAddressDetails =
      document.getElementsByClassName('contentAddressDetails')[cardId].innerText;
    const contentAddressDistrict =
      document.getElementsByClassName('contentAddressDistrict')[cardId].innerText;
    const contentAddressStreet = document
      .getElementsByClassName('contentAddressStreet')
      [cardId].innerText.split(',')[0];

    document.getElementById('streetField').value = contentAddressStreet;
    document.getElementById('districtField').value = contentAddressDistrict;
    document.getElementById(currentId).value = cardId;
    document.getElementById('cepField').value = contentAddressCep;
    document.getElementById('numberField').value = contentAddressNumber;
    document.getElementById('addressDetailsField').value = contentAddressDetails;

    inputCurrentId.value = cardId;
    setValueDistrict(contentAddressDistrict);
    setValueStreet(contentAddressStreet);
    setValueCEP(contentAddressCep);
    setValueNumber(contentAddressNumber);
    setValueComplement(contentAddressDetails);
  }

  async function insertAddress(juridicalPersonId) {
    const contentAddressType = document.getElementsByClassName('contentAddressType');
    const contentAddressStreet = document.getElementsByClassName('contentAddressStreet');
    const contentAddressNumber = document.getElementsByClassName('contentAddressNumber');
    const contentAddressDistrict = document.getElementsByClassName('contentAddressDistrict');
    const contentAddressDetails = document.getElementsByClassName('contentAddressDetails');
    const contentAddressCepCityState = document.getElementsByClassName('contentAddressCep');

    for (let i = 0; i < contentAddressType.length; i += 1) {
      const addressType = stateAddressTypeOptions.filter(
        ({ label }) => label === contentAddressType[i].innerText
      )[0];

      const data = {
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        Cd_juridical_person: juridicalPersonId,
        St_zip_code: contentAddressCepCityState[i].innerText.split(' -')[0],
        Cd_address_type: addressType.value,
        St_state: contentAddressCepCityState[i].innerText.split(' -')[2].trim(),
        St_city: contentAddressCepCityState[i].innerText.split(' -')[1].trim(),
        St_district: contentAddressDistrict[i].innerText,
        St_street: contentAddressStreet[i].innerText.split(',')[0],
        It_number: contentAddressNumber[i].innerText,
        St_address_details: contentAddressDetails[i].innerText,
        It_subject_type: 1,
        It_subject: 1,
      };

      await AppointmentApi({
        method: 'POST',
        url: '/Address',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  async function editAddressCard(cardIndex) {
    const cdAddress = document.getElementsByClassName('cdAddress')[cardIndex];
    const type = typeOfAddress2[cardIndex];
    const cep = document.getElementById('cepField')[cardIndex];
    const district = document.getElementById('districtField')[cardIndex];
    const street = document.getElementById('streetField')[cardIndex];
    const number = document.getElementById('numberField')[cardIndex];
    const details = document.getElementById('addressDetailsField')[cardIndex];
    const state = stateOfAddress.value[cardIndex];
    const city = valueAddressCity2[cardIndex];

    if (cep.value.length === 9 && district !== '' && street !== '') {
      // Monta o HTML que vai dentro dos cards
      const addressType = React.createElement('p', { className: 'contentAddressType' }, type);

      const addressNumber = React.createElement(
        'span',
        { className: 'contentAddressNumber' },
        number.value
      );
      const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
        `${street.value}, `,
        addressNumber,
      ]);
      const addressDistrict = React.createElement(
        'p',
        { className: 'contentAddressDistrict' },
        district.value
      );
      const addressDetails = React.createElement(
        'p',
        { className: 'contentAddressDetails' },
        details.value
      );
      const addressState = React.createElement('span', {}, state);
      const addressCity = React.createElement('span', {}, [`${city} - `, addressState]);
      const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
        `${cep.value} - `,
        addressCity,
      ]);
      const addressCard = React.createElement('div', { className: 'address-card' }, [
        addressType,
        addressStreet,
        addressDistrict,
        addressDetails,
        addressCep,
      ]);

      addressesCardContent.splice(cardIdPag, 1, { content: addressCard });
      // Adiciona o HTML no state
      await setAddressesCardContent(addressesCardContent);
    }

    if (isEdit) {
      const data = {
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
        St_zip_code: cep.innerText,
        Cd_address_type: type.value,
        St_state: state,
        St_city: city.innerText,
        St_district: district.innerText,
        St_street: street.innerText,
        It_number: number.innerText,
        St_address_details: details.value,
        Cd_address: parseInt(cdAddress.innerText, 10),
      };

      await AppointmentApi({
        method: 'PUT',
        url: '/Address',
        data,
      })
        .then((res) => res)
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  function fillFieldsContacts(Contacts) {
    Contacts?.forEach(({ cd_contact_type, st_content, cd_contact }) => {
      const { label: contactTypeName } = contactTypeOptions.find(
        ({ value }) => value === cd_contact_type
      );
      // Monta o HTML que vai dentro dos cards
      const cdContact = React.createElement(
        'p',
        {
          className: 'cdContact input-hidden',
        },
        cd_contact
      );
      const contactDescription = React.createElement(
        'p',
        { className: 'contentContactDescription' },
        st_content
      );
      const contactType = React.createElement(
        'p',
        { className: 'contentContactType' },
        contactTypeName
      );
      const contactCard = React.createElement('div', { className: 'contact-card' }, [
        cdContact,
        contactType,
        contactDescription,
      ]);

      // Adiciona o HTML no state
      setContactsCardContent(
        contactsCardContent.push({
          content: contactCard,
        })
      );
    });
  }

  function fillFieldsAddresses(addresses) {
    setLoadingAddress(true);
    addresses.forEach(
      ({
        st_zip_code,
        st_district,
        st_street,
        cd_address_type,
        it_number,
        st_address_details,
        st_state,
        st_city,
        cd_address,
      }) => {
        const typeFilter = adressTypeOptions.find(({ value }) => value === cd_address_type);
        // Monta o HTML que vai dentro dos cards
        const cdAddress = React.createElement(
          'p',
          {
            className: 'cdAddress input-hidden',
          },
          cd_address
        );

        const addressType = React.createElement(
          'p',
          { className: 'contentAddressType' },
          typeFilter.label
        );
        const addressNumber = React.createElement(
          'span',
          { className: 'contentAddressNumber' },
          it_number
        );
        const addressStreet = React.createElement('p', { className: 'contentAddressStreet' }, [
          `${st_street}, `,
          addressNumber,
        ]);
        const addressDistrict = React.createElement(
          'p',
          { className: 'contentAddressDistrict' },
          st_district
        );
        const addressDetails = React.createElement(
          'p',
          { className: 'contentAddressDetails' },
          st_address_details
        );
        const addressState = React.createElement('span', {}, st_state);
        const addressCity = React.createElement('span', {}, [`${st_city} - `, addressState]);
        const addressCep = React.createElement('p', { className: 'contentAddressCep' }, [
          `${st_zip_code} - `,
          addressCity,
        ]);
        const addressCard = React.createElement('div', { className: 'address-card' }, [
          cdAddress,
          addressType,
          addressStreet,
          addressDistrict,
          addressDetails,
          addressCep,
        ]);
        addressesCardContent.push({ content: addressCard });
        // Adiciona o HTML no state
        setAddressesCardContent(addressesCardContent);
      }
    );
    setLoadingAddress(false);
  }

  async function getContactsById() {
    await AppointmentApi.get(`/Contacts/GetByTypeID?id=${id}&user=1&tipoDePessoa=juridical`)
      .then((response) => {
        fillFieldsContacts(response.data);
      })
      .catch((err) => err);
  }

  async function getAddressById() {
    await AppointmentApi.get(`/Address/GetByTypeID?id=${id}&user=1&tipoDePessoa=juridical`)
      .then((response) => {
        fillFieldsAddresses(response.data);
      })
      .catch((err) => err);
  }

  function fillFields(data) {
    document.getElementById('name').value = data.st_name;
    document.getElementById('operation').value = data.st_operation;
    document.getElementById('cnpj').value = data.st_cnpj;
    document.getElementById('totalEmp').value = data.it_amount;
    document.getElementById('valueHour').value = data.dc_biling;
    setvaluePersonType(data.cd_juridical_person_type);
    fulfillCitySelect(data.JuridicalPersonEntity.st_birth_state, 1);

    // Contato
    data.contactList.map((dataContact) => newContactCardEditReq(dataContact));

    // Endereço
    data.addressList.map((dataAddress) => newAddressCardEditReq(dataAddress));
  }

  // get para edição
  async function getFields() {
    await AppointmentApi.get('/JuridicalPersons', {
      params: { id },
    })
      .then((response) => {
        fillFields(response.data);
      })
      .catch((err) => err);
  }

  function clearFields() {
    document.getElementById('name').value = '';
    document.getElementById('operation').value = '';
    document.getElementById('cnpj').value = '';
    document.getElementById('totalEmp').value = '';
    document.getElementById('valueHour').value = '';
    document.getElementById('personType').value = '';
    setTypeOfAddress2(null);
    setValueAddressCity2(null);
    setStateOfAddress(undefined);
    setValueStreet('');
    setValueNumber('');
    setValueDistrict('');
    setValueCEP('');
    setValueComplement('');
    setIsEditAddress(false);
  }

  function validateFields() {
    let isValid = true;
    const name = document.getElementById('name');
    const operation = document.getElementById('operation');
    const cnpj = document.getElementById('cnpj');
    const totalEmp = document.getElementById('totalEmp');
    const valueHour = document.getElementById('valueHour');

    if (name.value === '' || null) {
      name.classList.add('border-danger');
      isValid = false;
    } else {
      name.classList.remove('border-danger');
      isValid = true;
    }

    if (operation.value === '' || null) {
      operation.classList.add('border-danger');
      isValid = false;
    } else {
      operation.classList.remove('border-danger');
      isValid = true;
    }

    if (cnpj.value === '' || null) {
      cnpj.classList.add('border-danger');
      isValid = false;
    } else {
      cnpj.classList.remove('border-danger');
      isValid = true;
    }

    if (totalEmp.value === '' || null) {
      totalEmp.classList.add('border-danger');
      isValid = false;
    } else {
      totalEmp.classList.remove('border-danger');
      isValid = true;
    }

    if (valueHour.value === '' || null) {
      valueHour.classList.add('border-danger');
      isValid = false;
    } else {
      valueHour.classList.remove('border-danger');
      isValid = true;
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos');
    }

    return isValid;
  }

  function JuridicalPersonInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;
    }
    // Dados a ser enviados
    const data = {
      st_name: document.getElementById('name').value,
      st_operation: document.getElementById('operation').value,
      st_cnpj: document.getElementById('cnpj').value,
      it_amount: document.getElementById('totalEmp').value,
      dc_biling: document.getElementById('valueHour').value,
      cd_juridical_person_type: statePersonType,
      cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
    };

    if (isEdit) {
      Object.assign(data, { cd_juridical_person: parseInt(id, 10) });
    }

    AppointmentApi({
      method: isEdit ? 'PUT' : 'POST',
      url: '/JuridicalPersons',
      data,
    })
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          if (!isEdit) {
            await insertAddress(res.data);
            await insertContacts(res.data);
          }
          toast.success('Cadastro realizado com sucesso!');
          document.getElementById('save').disabled = false;
          if (redirect) {
            navigate('/Apontamento/PessoaJuridica');
          } else {
            clearFields();
            scrollTop();
          }
        }
      })
      .catch((err) => {
        toast.error('erro');
        document.getElementById('save').disabled = false;
        document.getElementById('saveAndNew').disabled = false;
      });
  }

  function fillSelects() {
    setStatePersonType(juridicalPersonOptions);
    setStateContactOptions(contactOptions);
    setStateAddressOptions(addressOptions);
  }

  useEffect(() => {
    if (optionsFilled === false) {
      fillPersonTypeOptions();
      fillContactTypeOptions();
      fillAddressTypeOptions();
      fillSelects();
      optionsFilled = true;
    }
    if (isEdit) {
      getFields();
      getContactsById();
      getAddressById();
    }
  }, [optionsFilled]);

  useEffect(() => {
    if (document.getElementById('cepField')) {
      // eslint-disable-next-line no-undef
      Inputmask({ mask: '99999-999' }).mask(document.getElementById('cepField'));

      document.getElementById('cepField').value = valueCEP;
      document.getElementById('districtField').value = valueDistrict;
      document.getElementById('streetField').value = valueStreet;
      document.getElementById('numberField').value = valueNumber;
      document.getElementById('addressDetailsField').value = valueComplement;
    }
  }, [loadingAddress]);

  return (
    <>
    <ToastContainer />
      <div className="page-content collaborators-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Apontamento/PessoaJuridica">Pessoa Jurídica</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {isEdit ? 'Edição' : 'Registro'} de Pessoa Jurídica
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Nome
                  <input id="name" className="mt-2" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Operação
                  <input id="operation" className="mt-2" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  CNPJ
                  <input id="cnpj" className="mt-2" type="text" placeholder="00.000.000/0000-00" />
                </label>
              </div>

              <div className="row mt-4">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Total de Funcionários
                  <input id="totalEmp" className="mt-2" type="text" />
                </label>
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Valor Hora
                  <input id="valueHour" className="mt-2" type="text" />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  Tipo de PJ
                  {statePersonTypeOptions && (
                    <SelectAntd
                      name="personType"
                      id="personType"
                      className="select-container mt-2"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valuePersonType}
                      optionFilterProp="label"
                      options={statePersonTypeOptions}
                      onChange={async (e, value) => {
                        if (e !== undefined) {
                          setStatePersonType(e, value);
                        } else {
                          setStatePersonType(undefined);
                        }
                      }}
                    />
                  )}
                  {!statePersonTypeOptions && <Select loading />}
                </label>
              </div>

              {loadingContact ? (
                <CompCollapsible title="Carregando..." />
              ) : (
                <CompCollapsible
                  title="Contato"
                  content={
                    <div className="row">
                      <DynamicCard
                        rightCardTitle="Contatos"
                        cardName="contact"
                        funcAddNew={newContactCard}
                        funcUpdtState={updateContactsState}
                        funcFillFields={fillContactFields}
                        isEdit={isEditContact}
                        funcEditCard={editContactCard}
                        funcClearFields={clearContactFields}
                        rightCardContent={contactsCardContent}
                        fields={
                          <>
                            <div className="row">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                Tipo de Contato
                                <Select
                                  options={stateContactTypeOptions}
                                  ref={(ref) => {
                                    if (ref) {
                                      contactRef = ref;
                                    }
                                  }}
                                  closeMenuOnSelect
                                  hideSelectedOptions={false}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  isSearchable
                                  components={{ MenuList, ValueContainer }}
                                  placeholder="Selecione..."
                                  noOptionsMessage={() => 'Nenhum registro encontrado'}
                                  styles={selectStyle}
                                />
                              </label>
                            </div>

                            <div className="row mt-4">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                htmlFor="descriptionField"
                              >
                                Descrição
                                <input id="descriptionField" className="mt-2" type="text" />
                              </label>
                            </div>
                          </>
                        }
                      />
                    </div>
                  }
                />
              )}

              {loadingAddress ? (
                <CompCollapsible title="Carregando..." />
              ) : (
                <CompCollapsible
                  title="Endereço"
                  content={
                    <div className="row">
                      <DynamicCard
                        rightCardTitle="Endereços"
                        cardName="address"
                        funcAddNew={newAddressCard}
                        funcUpdtState={updateAddressesState}
                        funcFillFields={fillAddressFields}
                        isEdit={isEditAddress}
                        funcEditCard={editAddressCard}
                        funcClearFields={clearAddressFields}
                        rightCardContent={isEdit ? AddressesCardContent : AddressesCardContent}
                        fields={
                          <>
                            <div className="row">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Tipo de Endereço
                                <SelectAntd
                                  options={stateAddressTypeOptions}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  optionFilterProp="label"
                                  showSearch
                                  allowClear
                                  value={typeOfAddress2}
                                  onChange={async (e, value) => {
                                    if (e !== undefined) {
                                      typeOfAddress = value.label;
                                      await setTypeOfAddress2(value.label);
                                    } else {
                                      typeOfAddress = null;
                                      await setTypeOfAddress2(null);
                                    }
                                  }}
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="cepField"
                              >
                                CEP
                                <input
                                  id="cepField"
                                  className="mt-2"
                                  type="text"
                                  maxLength="9"
                                  onKeyUp={(e) => {
                                    setValueCEP(e.target.value);
                                    getAddressByCep(e.target.value);
                                    if (e.key === 'Backspace') {
                                      document.getElementById('streetField').value = '';
                                      document.getElementById('districtField').value = '';
                                    }
                                  }}
                                />
                              </label>
                            </div>

                            <div className="row mt-3">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Estado
                                <SelectAntd
                                  options={stateOptions}
                                  showSearch
                                  allowClear
                                  optionFilterProp="label"
                                  value={stateOfAddress}
                                  onChange={async (value, e) => {
                                    if (e && e !== undefined) {
                                      fulfillCitySelect(e.value, 2);
                                      setStateOfAddress(e);
                                      setValueAddressCity2(null);
                                    } else {
                                      setStateOfAddress(null);
                                      setStateAddressCityOptions([]);
                                      setValueAddressCity2(null);
                                    }
                                  }}
                                  className="select-container mt-2"
                                  classNamePrefix="select"
                                  components={{ MenuList, ValueContainer }}
                                />
                              </label>

                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                Cidade
                                {stateAddressCityOptions && (
                                  <SelectAntd
                                    name="addressCity"
                                    showSearch
                                    className="select-container mt-2"
                                    placeholder="Selecione..."
                                    allowClear
                                    value={valueAddressCity2}
                                    optionFilterProp="label"
                                    options={stateAddressCityOptions}
                                    onChange={async (e, value) => {
                                      if (e !== undefined) {
                                        valueAddressCity = e;
                                        await setValueAddressCity2(e);
                                      } else {
                                        valueAddressCity = null;
                                        await setValueAddressCity2(null);
                                      }
                                    }}
                                  />
                                )}
                                {!stateAddressCityOptions && <SelectAntd loading />}
                              </label>
                            </div>

                            <div className="row mt-3">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="districtField"
                              >
                                Bairro
                                <input
                                  onKeyUp={(e) => {
                                    setValueDistrict(e.target.value);
                                  }}
                                  id="districtField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="streetField"
                              >
                                Rua
                                <input
                                  onKeyUp={(e) => {
                                    setValueStreet(e.target.value);
                                  }}
                                  id="streetField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>
                            </div>

                            <div className="row mt-3">
                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="numberField"
                              >
                                Número
                                <input
                                  onKeyUp={(e) => {
                                    setValueNumber(e.target.value);
                                  }}
                                  id="numberField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>

                              <label
                                className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                                htmlFor="addressDetailsField"
                              >
                                Complemento
                                <input
                                  onKeyUp={(e) => {
                                    setValueComplement(e.target.value);
                                  }}
                                  id="addressDetailsField"
                                  className="mt-2"
                                  type="text"
                                />
                              </label>
                            </div>
                          </>
                        }
                      />
                    </div>
                  }
                />
              )}
              {/* Row 11 */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      JuridicalPersonInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        JuridicalPersonInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    // insertContacts();
                    JuridicalPersonInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      JuridicalPersonInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest({ cd_juridical_person: id }, 'JuridicalPersons', 1);
                    navigate('/Apontamento/PessoaJuridica');
                  }}
                >
                  Excluir
                </button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
