/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { Select, DatePicker, Breadcrumb, Space } from 'antd';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppointmentApi } from '../../../../../services/api';
import { scrollTop, excludeRequest } from '../../../../Admin/assets/scripts/global';
import statesCities from '../../../../Website/assets/scripts/statesCities.json';
import Wysiwyg from '../../../../Admin/components/WYSIWYG';
import './style.scss';
import Quill from 'quill';

//let optionsFilled = false;

const workRegimeOptions = [];
let stateOptions = [];
let cityOptions = [];

export default function VacanciesRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.search.split('=').length > 1;
  const id = isEdit ? location.href.split('=')[1] : null;

  const [valueWorkRegime, setValueWorkRegime] = useState();
  const [stateWorkRegimeOptions, setStateWorkRegimeOptions] = useState();
  const [valueEssentials, setValueEssentials] = useState();
  const [stateEssentialOptions, setStateEssentialOptions] = useState();
  const [valueDifferentials, setValueDifferentials] = useState();
  const [stateDifferentialsOptions, setStateDifferentialsOptions] = useState();
  const [valueLevel, setValueLevel] = useState();
  const [stateLevelOptions, setStateLevelOptions] = useState();
  const [valueEmploymentRegime, setValueEmploymentRegime] = useState();
  const [stateEmploymentRegimeOptions, setEmploymentRegimeOptions] = useState();
  const [valueState, setValueState] = useState();
  const [valueCity, setValueCity] = useState();
  const [valueDate, setValueDate] = useState(moment());
  const [clear, setClear] = useState();
  const [stateCityOptions, setStateCityOptions] = useState();
  const [optionsFilled, setOptionsFilled] = useState(false);

  const handleNavigation = () => {
    if (location.pathname + location.search.split('=')[0] !== '/Recrutamento/Vagas/Registro?cd_vacancy') {
      setOptionsFilled(false);
    }
  };

  // Preenche as options do select de cidade
  function fulfillCitySelect(acronym) {
    cityOptions.length = 0;

    statesCities.states.forEach((state) => {
      if (state.acronym === acronym) {
        state.citys.forEach((city) => {
          cityOptions.push({ label: city, value: city });
        });
        setStateCityOptions(cityOptions);
      }
    });
  }

  // Preenche os campos da vaga na edição
  function fillFields(vacancyData) {
    const data = vacancyData;
    if (data && data !== undefined) {
      if (data.st_title !== undefined) {
        document.getElementById('title').value = data.st_title;
      }

      if (data.st_client !== undefined) {
        document.getElementById('client').value = data.st_client;
      }

      if (data.st_description && data.st_description !== undefined) {
        data.st_description = data.st_description.replaceAll(
          'style="font-size: 2.5rem"',
          'class="ql-size-huge"'
        );
        data.st_description = data.st_description.replaceAll(
          'style="font-size: 1.5rem"',
          'class="ql-size-large"'
        );
        data.st_description = data.st_description.replaceAll(
          'style="font-size: 0.75rem"',
          'class="ql-size-small"'
        );
        document
          .getElementsByClassName('wysiwyg-editor')[0]
          .getElementsByClassName('ql-editor')[0].innerHTML = data.st_description;
      }

      if (data.cd_employment_regime && data.cd_employment_regime !== undefined) {
        setValueEmploymentRegime(data.cd_employment_regime);
      }

      if (data.cd_level && data.cd_level !== undefined) {
        setValueLevel(data.cd_level);
      }

      if (data.cd_work_regime && data.cd_work_regime !== undefined) {
        setValueWorkRegime(data.cd_work_regime);
      }

      if (data.js_state && data.js_state !== undefined) {
        setValueState(data.js_state);
      }

      if (data.js_city && data.js_city !== undefined) {
        fulfillCitySelect(data.js_state);
        setValueCity(data.js_city);
      }

      if (data.ar_essentials && data.ar_essentials !== undefined) {
        const dataFormated = data.ar_essentials.split(';');
        const dataTeste = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const date of dataFormated) {
          if (date !== ' ') {
            dataTeste.push(parseInt(date, 10));
          }
        }
        setValueEssentials(dataTeste);
      }
      if (data.ar_differentials && data.ar_differentials !== undefined) {
        const dataFormated = data.ar_differentials.split(';');
        const dataTeste = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const date of dataFormated) {
          if (date !== ' ') {
            dataTeste.push(parseInt(date, 10));
          }
        }
        setValueDifferentials(dataTeste);
      }

      if (data.ar_publication_sites && data.ar_publication_sites !== undefined) {
        const temp = data.ar_publication_sites.split(';');
        if (temp.length > 1) {
          document.getElementById('pubNBW').checked = true;
          document.getElementById('pubLinkedin').checked = true;
        }
        if (temp[0] === 'Nbw Digital') {
          document.getElementById('pubNBW').checked = true;
        }
        if (temp[0] === 'LinkedIn') {
          document.getElementById('pubLinkedin').checked = true;
        }
      }
    }
  }

  // Traz a vaga na edição
  async function getVacancy() {
    const data = {
      params: { id }
    };

    try {
      const response = await AppointmentApi.get('Vacancies', data);
      fillFields(response.data);
    } catch (err) {
      toast.error('Servidor indisponivel!', err.response.data);
    }
  }

  // Puxa a State e City do .JSon
  stateOptions = [];
  statesCities.states.forEach((state) => {
    stateOptions.push({ label: state.name, value: state.acronym });
  });

  cityOptions = [];

  // Get Knowledge Levels
  async function fillLevelOptions() {
    try {
      const response = await AppointmentApi.get('/KnowledgeLevels/GetSelectOptions');
      setStateEssentialOptions(response.data);
    } catch (err) {
      toast.error('Servidor indisponivel!', err.message);
    }
  }

  // Get Essential
  async function fillEssentialOptions() {
    try {
      const response = await AppointmentApi.get('/RequirementQualifications' +
        '/GetSelectOptions');
      setStateEssentialOptions(response.data);
    } catch (err) {
      toast.error('Servidor indisponivel!', err.message);
    }
  }

  // Get Differentials
  async function fillDifferentialsOptions() {
    try {
      const response = await AppointmentApi.get('/RequirementQualifications/GetSelectOptions');
      setStateEssentialOptions(response.data);
    } catch (err) {
      toast.error('Servidor indisponivel!', err.message);
    }
  }

  // Get EmploymentRegime
  async function fillEmploymentRegimes(){
    try {
      const response = await AppointmentApi.get('/EmploymentRegimes/GetSelectOptions');
      setEmploymentRegimeOptions(response.data);
    } catch (err) {
      toast.error(err.message);
    }

  }

  // Get Work Regime
  async function fillWorkRegimes() {
    try {
      const response = await AppointmentApi.get('/WorkRegimes/GetSelectOptions');
      setStateWorkRegimeOptions(response.data);
    } catch (err) {
      toast.error(err.message);
    }
  }

  function changeValuePubSite() {
    const temp = [];
    const pubNBW = document.getElementById('pubNBW').checked;
    const pubLinkedin = document.getElementById('pubLinkedin').checked;
    if (pubNBW) {
      temp.push('NBW Digital');
    }
    if (pubLinkedin) {
      temp.push('LinkedIn');
    }
    return temp;
  }

  async function clearFields() {
    document.getElementById('title').value = '';
    document
      .getElementsByClassName('wysiwyg-editor')[0]
      .getElementsByClassName('ql-editor')[0].innerHTML = '<p><br></p>';

    setValueWorkRegime(undefined);
    setValueEssentials(undefined);
    setValueDifferentials(undefined);
    setValueEmploymentRegime(undefined);
    setValueCity(undefined);
    setValueState(undefined);

    document.getElementById('pubNBW').checked = false;
    document.getElementById('pubLinkedin').checked = false;
  }

  function validateFields() {
    let isValid = true;

    const workRegime = document.getElementsByName('work-regime')[0].firstChild;
    const level = document.getElementsByName('levels')[0].firstChild;
    const essentials = document.getElementsByName('essentials')[0].firstChild;
    const state = document.getElementsByName('state')[0].firstChild;
    const city = document.getElementsByName('city')[0].firstChild;
    const title = document.getElementById('title');
    const description = document.getElementsByClassName('wysiwyg-editor')[0];
    const date = document.getElementsByName('date')[0].offsetParent.offsetParent;
    const employmentRegime = document.getElementsByName('employmentRegime')[0].firstChild;

    if (title.value === '') {
      title.classList.add('border-danger');
      isValid = false;
    } else {
      title.classList.remove('border-danger');
    }

    if (valueLevel === undefined || !valueLevel) {
      level.classList.add('border-danger');
      isValid = false;
    } else {
      level.classList.remove('border-danger');
    }

    if (description.getElementsByClassName('ql-editor')[0].innerHTML === '<p><br></p>') {
      description.classList.add('border-danger');
      description.previousSibling.classList.add('border-danger');
      isValid = false;
    } else {
      description.classList.remove('border-danger');
      description.previousSibling.classList.remove('border-danger');
    }

    if (!valueDate || valueDate === undefined) {
      date.classList.add('border-danger');
      isValid = false;
    } else {
      date.classList.remove('border-danger');
    }
    if (valueEssentials === undefined || !valueEssentials) {
      essentials.classList.add('border-danger');
      isValid = false;
    } else {
      essentials.classList.remove('border-danger');
    }

    if (valueWorkRegime === undefined || !valueWorkRegime) {
      workRegime.classList.add('border-danger');
      isValid = false;
    } else {
      workRegime.classList.remove('border-danger');
    }

    if (valueCity === undefined || !valueCity) {
      city.classList.add('border-danger');
      isValid = false;
    } else {
      city.classList.remove('border-danger');
    }

    if (valueState === undefined || !valueState) {
      state.classList.add('border-danger');
      isValid = false;
    } else {
      state.classList.remove('border-danger');
    }

    if (valueEmploymentRegime === undefined || !valueEmploymentRegime) {
      employmentRegime.classList.add('border-danger');
      isValid = false;
    } else {
      employmentRegime.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }
    return isValid;
  }
  // Concatena os valores do multiselect
  function concatenateMultiSelectValue(multiselect) {
    let result = '';
    for (let index = 0; index < multiselect.length; index += 1) {
      result += `${multiselect[index]}; `;
    }

    return result;
  }

  // Concatena os valores dos sites
  function concatenateSitesValue(sites) {
    let result = '';
    for (let index = 0; index < sites.length; index += 1) {
      result += `${sites[index]}; `;
    }

    return result;
  }

  function VacanciesInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      let description = document
        .getElementsByClassName('wysiwyg-editor')[0]
        .getElementsByClassName('ql-editor')[0].innerHTML;
      description = description.replaceAll('class="ql-size-huge"', 'style="font-size: 2.5rem"');
      description = description.replaceAll('class="ql-size-large"', 'style="font-size: 1.5rem"');
      description = description.replaceAll('class="ql-size-small"', 'style="font-size: 0.75rem"');

      // Dados a ser enviados
      const data = {
        st_title: document.getElementById('title').value,
        st_description: description,
        cd_level: valueLevel,
        ar_essentials: valueEssentials ? concatenateMultiSelectValue(valueEssentials) : null,
        js_city: valueCity,
        js_state: valueState,
        cd_employment_regime: valueEmploymentRegime,
        cd_work_regime: valueWorkRegime,
        ar_differentials: valueDifferentials
          ? concatenateMultiSelectValue(valueDifferentials)
          : null,
        ar_publication_sites: concatenateSitesValue(changeValuePubSite()),
        st_client: document.getElementById('client').value,
        dt_expiration: valueDate,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };
      if (isEdit) {
        Object.assign(data, { cd_vacancy: parseInt(id, 10) });
      }

      try {
        const response = AppointmentApi({
          method: isEdit ? 'PUT' : 'POST',
          url: 'Vacancies',
          data,
        });
        if (response.status === 200 || response.status === 201){
          toast.success(response.data);
          document.getElementById('save').disabled = false;
          if (redirect){
            navigate('/Recrutamento/Vagas');
          } else {
            clearFields();
            scrollTop();
          }
        }
      } catch (err) {
        toast.error(err.response.data);
        document.getElementById('save').disabled = false;
        document.getElementById('saveAndNew').disabled = false;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (optionsFilled === false) {
        await fillLevelOptions();
        await fillEssentialOptions();
        await fillEmploymentRegimes();
        await fillWorkRegimes();
        await fillDifferentialsOptions();
  
        //optionsFilled = true;
        handleNavigation();
      }
      if (isEdit) {
        await getVacancy();
      }
    };
    fetchData();
  }, [optionsFilled]);

  return (
    <>
    <ToastContainer />
      <div className="page-content vacancy-register-row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Recrutamento/Vagas">Vagas</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Registro de Vagas</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-body">
              {/* Título da vaga */}
              <div className="row">
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6" htmlFor="title">
                  Título
                  <input type="text" id="title" />
                </label>

                {/* Nível */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Nível
                  {stateLevelOptions && (
                    <Select
                      name="levels"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueLevel}
                      optionFilterProp="label"
                      options={stateLevelOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueLevel(e);
                        } else {
                          setValueLevel(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateLevelOptions && <Select loading />}
                </label>
              </div>

              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Data de Vencimento
                  <DatePicker
                    name="date"
                    showToday={false}
                    placeholder="Selecionar Data"
                    value={valueDate}
                    defaultValue={moment()}
                    onChange={(e) => {
                      if (e) {
                        setValueDate(e);
                      } else {
                        setValueDate(undefined);
                      }
                    }}
                    format="DD/MM/YYYY"
                  />
                </label>

                {/* Clientes */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6" htmlFor="client">
                  Clientes
                  <input type="text" id="client" />
                </label>
              </div>

              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Requisitos Essenciais
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                    }}
                  >
                    {stateEssentialOptions && (
                      <Select
                        name="essentials"
                        showSearch
                        mode="multiple"
                        style={{
                          width: '100%',
                        }}
                        valueEmploymentRegime
                        stateEmploymentRegimeOptions
                        placeholder="Selecione..."
                        allowClear
                        value={valueEssentials}
                        optionFilterProp="label"
                        options={stateEssentialOptions}
                        onChange={(e) => {
                          if (e !== undefined) {
                            setValueEssentials(e);
                          } else {
                            setValueEssentials(undefined);
                          }
                        }}
                        maxTagCount="responsive"
                      />
                    )}
                  </Space>
                  {!stateEssentialOptions && <Select loading />}
                </label>

                {/* Requisitos Diferenciais */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Requisitos Diferenciais
                  {stateDifferentialsOptions && (
                    <Select
                      name="differentials"
                      showSearch
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      valueEmploymentRegime
                      stateEmploymentRegimeOptions
                      placeholder="Selecione..."
                      allowClear
                      value={valueDifferentials}
                      optionFilterProp="label"
                      options={stateDifferentialsOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueDifferentials(e);
                        } else {
                          setValueDifferentials(undefined);
                        }
                      }}
                      maxTagCount="responsive"
                    />
                  )}
                  {!stateDifferentialsOptions && <Select loading />}
                </label>
              </div>

              {/* Regime de Contratação */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Regime de Contratação
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                    }}
                  >
                    {stateEmploymentRegimeOptions && (
                      <Select
                        direction="vertical"
                        name="employmentRegime"
                        showSearch
                        placeholder="Selecione..."
                        allowClear
                        value={valueEmploymentRegime}
                        optionFilterProp="label"
                        options={stateEmploymentRegimeOptions}
                        onChange={(e) => {
                          if (e !== undefined) {
                            setValueEmploymentRegime(e);
                          } else {
                            setValueEmploymentRegime(undefined);
                          }
                        }}
                      />
                    )}
                    {!stateEmploymentRegimeOptions && <Select loading />}
                  </Space>
                </label>

                {/* Regime de Trabalho */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Regime de Trabalho
                  {stateWorkRegimeOptions && (
                    <Select
                      name="work-regime"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueWorkRegime}
                      optionFilterProp="label"
                      options={stateWorkRegimeOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueWorkRegime(e);
                        } else {
                          setValueWorkRegime(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateWorkRegimeOptions && <Select loading />}
                </label>
              </div>

              {/* Estado */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Estado
                  {stateOptions && (
                    <Select
                      name="state"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueState}
                      optionFilterProp="label"
                      options={stateOptions}
                      onChange={(e) => {
                        if (e && e !== undefined) {
                          fulfillCitySelect(e);
                          setValueState(e);
                        }
                      }}
                    />
                  )}
                  {!stateOptions && <Select loading />}
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  Cidade
                  {stateCityOptions && (
                    <Select
                      name="city"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueCity}
                      optionFilterProp="label"
                      options={stateCityOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueCity(e);
                        }
                      }}
                    />
                  )}
                  {!stateCityOptions && <Select loading name="city" />}
                </label>
              </div>

              <div className="row mt-4">
                {/* Descrição */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  htmlFor="description"
                >
                  Descrição da Vaga
                  <Wysiwyg />
                </label>
              </div>

              <div className="row mt-4">
                {/* Sites de Publicação */}
                <p className="text-color-light form-label custom-label">Sites de Publicação</p>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex mt-1">
                  <div className="form-check me-3">
                    <label htmlFor="pubNBW" className="form-check-label" color="white">
                      <input type="checkbox" className="form-check-input me-3" id="pubNBW" />
                      NBW Digital
                    </label>
                  </div>
                  <div className="form-check">
                    <label htmlFor="pubLinkedin" className="form-check-label" color="white">
                      <input type="checkbox" className="form-check-input me-3" id="pubLinkedin" />
                      LinkedIn
                    </label>
                  </div>
                </div>
              </div>

              {/* Botões */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      VacanciesInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        VacanciesInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  type="button"
                  id="save"
                  className="btn btn-success me-3"
                  onClick={() => {
                    VacanciesInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      VacanciesInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'Vacancies');
                    navigate('/Recrutamento/Vagas');
                  }}
                >
                  Excluir
                </button>
                <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="response" className="alert" style={{ display: 'none' }} role="alert" />
      </div>
    </>
  );
}
