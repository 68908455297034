import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaDesktop, FaUserLock, FaCalendarAlt } from 'react-icons/fa';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
//import { Sidebar } from 'react-sidebar';
import PerfectScrollbar from 'react-perfect-scrollbar';

import '../../../../custom.scss';
import '../../admin.scss';
import './style.scss';
import JobRole from '../../../Appointment/pages/JobRole/List';


export default function SidebarMenu(props) {
  useEffect(() => {
    // Verifica e aplica margin-top ao menu lateral, se necessário
    const permissions = JSON.parse(localStorage.getItem('nbw__permissions'));

    if (window.location.pathname !== '/Login') {
      const menuElement = document.getElementById('menu-mt');
      if (menuElement) {
        menuElement.style.marginTop = '62px';
      } else {
        console.log('menu-mt não encontrado');
      }
    }
    // A função de limpeza não é necessária aqui, então retornamos `undefined` por padrão
  }, [props.collapsed]);

  const permissions = JSON.parse(localStorage.getItem('nbw__permissions'));

  return (
    <Sidebar id="sidebarMenu" collapsed={props.defaultCollapsed}>
      <PerfectScrollbar className="sidemenu-scrollbar" options={{ wheelPropagation: false }}>
        <Menu>
          {(permissions[18]?.view || permissions[17]?.view || permissions[26]?.view) && (
            <SubMenu className="menu-mt" title="Admin" icon={<FaUserLock />}>
              {permissions[18]?.view && (
                <SubMenu label="Perfis" title="Perfis">
                  <MenuItem>
                    <Link to="/Admin/Perfis">Listar</Link>
                  </MenuItem>
                  {permissions[18]?.insert && (
                    <MenuItem>
                      <Link to="/Admin/Perfis/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[17]?.view && (
                <MenuItem>
                  <Link to="/Admin/Permissoes">Permissões</Link>
                </MenuItem>
              )}
              {permissions[24]?.view && (
                <SubMenu label="Regras" title="Regras">
                  <MenuItem>
                    <Link to="/Admin/Regras">Listar</Link>
                  </MenuItem>
                  {permissions[24]?.insert && (
                    <MenuItem>
                      <Link to="/Admin/Regras/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[26]?.view && (
                <SubMenu label="Usuários" title="Usuários">
                  <MenuItem>
                    <Link to="/Admin/Usuarios">Listar</Link>
                  </MenuItem>
                  {permissions[26]?.insert && (
                    <MenuItem>
                      <Link to="/Admin/Usuarios/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </SubMenu>
          )}
          {permissions[2]?.view && (
            <SubMenu className="menu-mt" title="Apontamento" icon={<FaCalendarAlt />}>
              <MenuItem>
                <Link to="/Apontamento">Apontamento</Link>
              </MenuItem>
              {permissions[12]?.view && (
                <SubMenu label="Cargos" title="Cargo">
                  <MenuItem>
                    <Link to="/Apontamento/Cargo">Listar</Link>
                  </MenuItem>
                  {permissions[12]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Cargo/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[6]?.view && (
                <SubMenu label="Colaboradores" title="Colaboradores">
                  <MenuItem>
                    <Link to="/Apontamento/Colaboradores">Listar</Link>
                  </MenuItem>
                  {permissions[6]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Colaboradores/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[13]?.view && (
                <SubMenu label="Pessoa Jurídica" title="Pessoa Jurídica">
                  <MenuItem>
                    <Link to="/Apontamento/Pessoa-Juridica">Listar</Link>
                  </MenuItem>
                  {permissions[13]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Pessoa-Juridica/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[19]?.view && (
                <SubMenu label="Projetos" title="Projetos">
                  <MenuItem>
                    <Link to="/Apontamento/Projeto">Listar</Link>
                  </MenuItem>
                  {permissions[19]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Projeto/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[10]?.view && (
                <SubMenu label="Tipo de Contato" title="Tipo de Contato">
                  <MenuItem>
                    <Link to="/Apontamento/Tipo-Contato">Listar</Link>
                  </MenuItem>
                  {permissions[10]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Tipo-Contato/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[1]?.view && (
                <SubMenu label="Tipo de Endereço" title="Tipo de Endereço">
                  <MenuItem>
                    <Link to="/Apontamento/Tipo-Endereco">Listar</Link>
                  </MenuItem>
                  {permissions[1]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Tipo-Endereco/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[14]?.view && (
                <SubMenu label="Tipo de PJ" title="Tipo de PJ">
                  <MenuItem>
                    <Link to="/Apontamento/Tipo-pj">Listar</Link>
                  </MenuItem>
                  {permissions[14]?.insert && (
                    <MenuItem>
                      <Link to="/Apontamento/Tipo-pj/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </SubMenu>
          )}
          {(permissions[27]?.view || permissions[5]?.view || permissions[15]?.view || permissions[22]?.view) && (
            <SubMenu className="menu-mt" title="Recrutamento" icon={<FaDesktop />}>
              {permissions[5]?.view && (
                <SubMenu label="Candidatos" title="Candidatos" aria-label="test">
                  <MenuItem>
                    <Link to="/Recrutamento/Candidatos">Listar</Link>
                  </MenuItem>
                  {permissions[5]?.insert && (
                    <MenuItem>
                      <Link to="/Recrutamento/Candidatos/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[15]?.view && (
                <SubMenu label="Níveis" title="Níveis">
                  <MenuItem>
                    <Link to="/Recrutamento/Niveis">Listar</Link>
                  </MenuItem>
                  {permissions[15]?.insert && (
                    <MenuItem>
                      <Link to="/Recrutamento/Niveis/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[22]?.view && (
                <SubMenu label="Requisitos" title="Requisitos">
                  <MenuItem>
                    <Link to="/Recrutamento/Requisitos">Listar</Link>
                  </MenuItem>
                  {permissions[22]?.insert && (
                    <MenuItem>
                      <Link to="/Recrutamento/Requisitos/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions[27]?.view && (
                <SubMenu label="Vagas" title="Vagas">
                  <MenuItem>
                    <Link to="/Recrutamento/Vagas">Listar</Link>
                  </MenuItem>
                  {permissions[27]?.insert && (
                    <MenuItem>
                      <Link to="/Recrutamento/Vagas/Registro">Cadastrar</Link>
                    </MenuItem>
                  )}
                </SubMenu>
              )}
            </SubMenu>
          )}
        </Menu>
      </PerfectScrollbar>
    </Sidebar>
  );
}
