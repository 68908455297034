import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Breadcrumb } from 'antd';
import moment from 'moment';
import { AppointmentApi } from '../../../../../services/api';
import './style.scss';
import TableAntd from '../../../../Admin/components/TableAntd';

export default function VacanciesList() {
  const [data, setData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getData = async () => {
    setLoadingTable(true);
    try {
      const response = await AppointmentApi.get('/Vacancies/GetTable');
      const newResponse = response.data.map((item) => ({ ...item, key: item.cd_vacancy }));

      setData(newResponse);
    } catch (err) {
      if (err.response === undefined) {
        toast.error("Servidor indisponivel!", err.message);
        setData([]);
      }
      setData([]);
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect( () => {
    const fetchData = async () => {
      await getData();
    }
    fetchData();
  }, [])

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'st_title',
        title: 'Titulo',
        key: 'st_title',
        ...getColumnSearchProps('st_title', 'Title'),
        sorter: (a, b) => a.st_title.length - b.st_title.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
        width: '150px',
      },
      {
        dataIndex: 'st_description',
        title: 'Descrição',
        key: 'st_description',
        ...getColumnSearchProps('st_description', 'Description'),
        sorter: (a, b) => a.st_description.length - b.st_description.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '250px',
        // eslint-disable-next-line react/destructuring-assignment
        render: (text) => <p>{text.substr(0, 100)}...</p>,
      },
      {
        dataIndex: 'st_knowledgeLevel',
        title: 'Nível',
        key: 'st_knowledgeLevel',
        ...getColumnSearchProps('st_knowledgeLevel', 'Level'),
        sorter: (a, b) => a.st_knowledgeLevel.length - b.st_knowledgeLevel.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'ar_essentials',
        title: 'Requisitos Ess.',
        key: 'ar_essentials',
        ...getColumnSearchProps('ar_essentials', 'Essential'),
        sorter: (a, b) => a.ar_essentials.length - b.ar_essentials.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
        // eslint-disable-next-line react/destructuring-assignment
        render: (text) => <p>{text.substr(0, 21)}...</p>,
      },
      {
        dataIndex: 'ar_differentials',
        title: 'Requisitos Dif.',
        key: 'ar_differentials',
        ...getColumnSearchProps('ar_differentials', 'Differentials'),
        sorter: (a, b) => a.ar_differentials.length - b.ar_differentials.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
        // eslint-disable-next-line react/destructuring-assignment
        render: (text) => <p>{text.substr(0, 21)}...</p>,
      },
      {
        dataIndex: 'st_emploimentRegime',
        title: 'Reg. Trabalho',
        key: 'st_emploimentRegime',
        ...getColumnSearchProps('st_emploimentRegime', 'EmploymentRegime'),
        sorter: (a, b) => a.st_emploimentRegime.length - b.st_emploimentRegime.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'js_state',
        title: 'Estado',
        key: 'js_state',
        ...getColumnSearchProps('js_state', 'Estate'),
        sorter: (a, b) => a.js_state.length - b.js_state.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'js_city',
        title: 'Cidade',
        key: 'js_city',
        ...getColumnSearchProps('js_city', 'City'),
        sorter: (a, b) => a.js_city.length - b.js_city.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'st_work_regime',
        title: 'Reg. Contratação',
        key: 'st_work_regime',
        ...getColumnSearchProps('st_work_regime', 'WorkRegime'),
        sorter: (a, b) => a.st_work_regime.length - b.st_work_regime.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'st_client',
        title: 'Cliente',
        key: 'st_client',
        ...getColumnSearchProps('st_client', 'Cliente'),
        sorter: (a, b) => a.st_clients.length - b.st_clients.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '150px',
      },
      {
        dataIndex: 'dt_publication',
        title: 'Dt. Publicação',
        width: '120px',
        key: 'dt_publication',
        ...getColumnSearchProps('dt_publication', 'Publication'),
        sorter: (a, b) => a.dt_publication.length - b.dt_publication.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        render: (text) => <p>{moment(text).format('DD/MM/YYYY')}</p>,
      },
      {
        dataIndex: 'dt_expiration',
        title: 'Dt. Expiração',
        key: 'dt_expiration',
        ...getColumnSearchProps('dt_expiration', 'Expiration'),
        sorter: (a, b) => a.dt_expiration.length - b.dt_expiration.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '120px',
        render: (text) => <p>{moment(text).format('DD/MM/YYYY')}</p>,
      },
      {
        dataIndex: 'ar_publication_sites',
        title: 'Sites',
        key: 'ar_publication_sites',
        ...getColumnSearchProps('ar_publication_sites', 'PublicationSite'),
        sorter: (a, b) => a.ar_publication_sites.length - b.ar_publication_sites.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
        width: '100px',
      },
    ];
    return columns;
  };

  return (
    <>
    <ToastContainer />
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Vagas</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                nameOfResquest="vacancies"
                btnNew
                scroll={{ x: 2150, y: 500 }}
                setData={setData}
                cdPage="cd_vacancy"
                routePermissions="vacancies"
                route="Vagas/Registro"
                getData={() => getData()}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
