import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight, FaFacebookF, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import './style.scss';
import logo from '../../../../img/logo-light.png';

export default function Footer() {
  function changeNavBarStyle() {
    setTimeout(() => {
      if (window.location.href.split('/')[3] === '') {
        document.getElementById('navbar').classList.remove('navbar-black');
      } else {
        document.getElementById('navbar').classList.add('navbar-black');
      }

      window.scrollTo(0, 0);
    }, 10);
  }

  useEffect(() => {
    if (window.location.href.split('/')[3] === '') {
      document.getElementById('navbar').classList.remove('navbar-black');
    } else {
      document.getElementById('navbar').classList.add('navbar-black');
    }
  });

  return (
    <>
      <footer id="footer">
        <div className="container container-lg my-md-4">
          <div className="row py-lg-5 py-md-0">
            <div className="col-md-4 col-lg-4 mb-md-5 mb-2 mb-lg-0 text-center pt-3 text-md-start ">
              <h5 className="mb-md-4">NBW Digital</h5>
              <p className="mb-md-3 mb-0">Transformação digital para otimizar resultados.</p>
              <div className="row justify-content-center mt-md-5 mt-2">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <img
                    src={logo}
                    className="footer-logo"
                    title="NBW Digital"
                    alt="Logo NBW Digital"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-md-4 mb-2 mb-md-0 text-center pt-3 text-md-start">
              <h5 className="mb-md-4 ps-2">Páginas</h5>
              <ul
                className="pages-links ps-2 mb-0 d-flex justify-content-start justify-content-sm-center justify-content-md-start flex-row text-start">
                <li className="mb-md-2">
                  <FaChevronRight size="13" color="#36b4e5" />
                  <Link
                    to="/About"
                    onClick={(e) => changeNavBarStyle(e)}
                    className="nav-link px-md-2 px-1"
                  >
                    Quem somos
                  </Link>
                </li>
                <li className="mb-md-2">
                  <FaChevronRight size="13" color="#36b4e5" />
                  <Link
                    to="/Services"
                    onClick={(e) => changeNavBarStyle(e)}
                    className="nav-link px-md-2 px-1"
                  >
                    Serviços
                  </Link>
                </li>
                <li className="mb-md-2">
                  <FaChevronRight size="13" color="#36b4e5" />
                  <Link
                    to="/Contact"
                    onClick={(e) => changeNavBarStyle(e)}
                    className="nav-link px-md-2 px-1"
                  >
                    Contato
                  </Link>
                </li>
                <li className="mb-md-2">
                  <FaChevronRight size="13" color="#36b4e5" />
                  <Link
                    to="/Carreers"
                    onClick={(e) => changeNavBarStyle(e)}
                    className="nav-link px-md-2 px-1"
                  >
                    Carreiras
                  </Link>
                </li>
                <li className="mb-md-2">
                  <FaChevronRight size="13" color="#36b4e5" />
                  <a href="/" className="nav-link px-md-2 px-1" data-bs-toggle="modal" data-bs-target="#modalPoliticaDePrivacidade">
                    Politica de Privacidade
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-3 mb-md-4 mb-2 mb-lg-0 pt-3 text-start">
              <h5 className="mb-md-4 text-center text-md-start">Matriz Gaspar</h5>
              <p className="mb-2">
                <span className="text-color-light">Endereço:</span>
                <a
                  href="https://www.google.com/maps/place/NBW+Digital/@-26.9250964,-48.9600336,18.11z/data=!4m5!3m4!1s0x94df25227540538f:0x95ed32d01cf47242!8m2!3d-26.9249947!4d-48.9590703"
                  target="_blank"
                  rel="noreferrer"
                  title="Ir para Google Maps"
                >
                  {' '}
                  Rua Hercílio Fides Zimmermann, 285, 1º Andar, Margem Esquerda, Gaspar - SC,
                  89116-650
                </a>
              </p>
              <p className="mb-2">
                <span className="text-color-light">Telefone:</span>
                <a
                  href="https://api.whatsapp.com/send?phone=554733327853"
                  target="_blank"
                  rel="noreferrer"
                  title="Whatsapp"
                >
                  {' '}
                  (47) 3332-7853
                </a>
              </p>
              <p className="mb-2">
                <span className="text-color-light">Email:</span>
                <a
                  href="mailto:contato@nbwdigital.com.br"
                  target="_blank"
                  rel="noreferrer"
                  title="Abrir email"
                >
                  {' '}
                  contato@nbwdigital.com.br
                </a>
              </p>
            </div>
            <div className="col-lg-3 text-center pt-3 text-lg-start">
              <h5 className="mb-md-4">Siga-nos</h5>
              <ul className="footer-social-icons mt-0 mt-lg-3 d-flex flex-row">
                <li className="social-circle">
                  <a
                    className="facebook-icon"
                    href="https://www.facebook.com/nbwdigital/"
                    target="_blank"
                    rel="noreferrer"
                    title="Facebook"
                  >
                    <FaFacebookF size="22" color="#fff" />
                  </a>
                </li>
                <li className="social-circle mx-2">
                  <a
                    className="linkedin-icon"
                    href="https://www.linkedin.com/company/nbwdigital/"
                    target="_blank"
                    rel="noreferrer"
                    title="Linkedin"
                  >
                    <FaLinkedinIn size="22" color="#fff" />
                  </a>
                </li>
                <li className="social-circle me-2">
                  <a
                    className="instagram-icon"
                    href="https://www.instagram.com/nbwdigital/"
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram"
                  >
                    <FaInstagram size="22" color="#fff" />
                  </a>
                </li>
                <li className="social-circle">
                  <a
                    className="whatsapp-icon"
                    href="https://api.whatsapp.com/send?phone=554733327853"
                    target="_blank"
                    rel="noreferrer"
                    title="Whatsapp"
                  >
                    <FaWhatsapp size="22" color="#fff" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <a href="/" className="nav-link text-center" data-bs-toggle="modal" data-bs-target="#modal">
          Nós estamos de acordo com a LGPD - Saiba Mais
        </a>
        <div className="container container-lg">
          <div className="footer-copyright footer-copyright-style-2">
            <div className="py-2">
              <div className="row py-md-3">
                <div className="col d-flex align-items-center justify-content-center mb-md-4 mb-lg-0">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                    Copyright 2021 © NBW Digital. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Modal Politica de Privacidade */}
      <div className="modal" id="modalPoliticaDePrivacidade" aria-hidden="true" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Política de Privacidade</strong>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>
                <strong>1. Introdução</strong>
              </p>
              <p>
                Bem-vindo à NBW DIGITAL! A sua privacidade é importante para nós. Esta Política de Privacidade explica
                como coletamos, usamos,
                armazenamos e compartilhamos suas informações pessoais quando você visita nosso site ou utiliza nossos
                serviços.
              </p>{' '}
              <p>
                <strong>2. Informações que coletamos</strong>
              </p>
              <p>
                Nós coletamos os seguintes tipos de informações:
              </p>{' '}
              <p>
                - Informações de contato: nome, e-mail, telefone.
              </p>{' '}
              <p>
                - Informações de navegação: endereço IP, tipo de navegador, páginas acessadas.
              </p>{' '}
              <p>
                - Informações fornecidas diretamente por você: mensagens enviadas através de formulários de contato.
              </p>{' '}
              <p>
                <strong>3. Como Usamos as Informações</strong>
              </p>
              <p>
                Utilizamos suas informações para:
              </p>{' '}
              <p>
                - Fornecer e melhorar nossos serviços.
              </p>{' '}
              <p>
                - Personalizar sua experiência no site.
              </p>{' '}
              <p>
                - Comunicar-se com você.
              </p>{' '}
              <p>
                - Cumprir obrigações legais e regulatórias.
              </p>{' '}
              <p>
                <strong>4. Compartilhamento de Informações</strong>
              </p>
              <p>
                Não compartilhamos suas informações pessoais com terceiros,
                exceto conforme necessário para cumprir a lei,
                proteger nossos direitos ou operar nossos serviços.
              </p>{' '}
              <p>
                <strong>5. Segurança das Informações</strong>
              </p>
              <p>
                Implementamos medidas de segurança adequadas para
                proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.
              </p>{' '}
              <p>
                <strong>6. Seus Direitos</strong>
              </p>
              <p>
                Você tem o direito de acessar, corrigir, excluir e limitar o uso de suas informações pessoais.
                Para exercer esses direitos, entre em contato conosco pelo e-mail [contato@nbwdigital.com.br].
              </p>{' '}
              <p>
                <strong>7. Uso de Cookies</strong>
              </p>
              <p>
                Nosso site utiliza cookies para melhorar sua experiência de navegação.
                Os cookies são pequenos arquivos de texto armazenados em seu dispositivo.
                Você pode gerenciar suas preferências de cookies através das configurações do seu navegador.
              </p>{' '}
              <p>
                <strong>8. Alterações na Política de Privacidade</strong>
              </p>
              <p>
                Podemos atualizar esta Política de Privacidade periodicamente.
                Recomendamos que você revise esta página regularmente para se manter informado sobre quaisquer mudanças.
              </p>{' '}
              <p>
                <strong>9. Contato</strong>
              </p>
              <p>
                Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco pelo e-mail [contato@nbwdigital.com.br].
              </p>{' '}
              <p>
                <strong>Fim.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Modal LGPD */}
      <div className="modal" id="modal" aria-hidden="true" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <strong>Lei Geral de Proteção de Dados Pessoais</strong>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>
                <strong>O que é a LGPD?</strong>
              </p>
              <p>
                A Lei n. 13.709, de 14 de agosto de 2018, denominada Lei Geral de Proteção de Dados
                Pessoais - LGPD, dispõe sobre o <strong>tratamento</strong> de{' '}
                <strong>dados pessoais</strong>, inclusive nos meios digitais, por pessoa natural ou
                por pessoa jurídica de direito público ou privado, com o objetivo de proteger os
                direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da
                personalidade da pessoa natural.
              </p>{' '}
              <p>Estão expressamente estabelecidos na LGPD os seguintes fundamentos:</p>{' '}
              <p>I - o respeito à privacidade;</p> <p>II - a autodeterminação informativa;</p>{' '}
              <p>III - a liberdade de expressão, de informação, de comunicação e de opinião;</p>{' '}
              <p>IV - a inviolabilidade da intimidade, da honra e da imagem;</p>{' '}
              <p>V - o desenvolvimento econômico e tecnológico e a inovação;</p>{' '}
              <p>VI - a livre iniciativa, a livre concorrência e a defesa do consumidor;</p>{' '}
              <p>
                e VII - os direitos humanos, o livre desenvolvimento da personalidade, a dignidade e
                o exercício da cidadania pelas pessoas naturais.
              </p>{' '}
              <p>A LGPD está dividida em 10 capítulos e 65 artigos.</p>{' '}
              <p>
                O <strong>capítulo</strong> I é dedicado às <strong>disposições gerais</strong>, em
                que são encontrados os princípios que fundamentam a proteção de dados pessoais (art.
                2º), o âmbito de aplicação territorial da lei (art. 3º) e conceitos básicos (art.
                5º).
              </p>{' '}
              <p>
                Entre os conceitos apresentados pela LGPD, destaca-se o de{' '}
                <strong>dados pessoais</strong>, que são informações relacionadas à pessoa natural
                identificada ou identificável (art. 5º, I).
              </p>{' '}
              <p>
                Assim, a LGPD protege não só a informação que identifica uma pessoa natural, como
                também aquela que, cruzada com outras, permite a identificação da pessoa natural.
              </p>{' '}
              <p>
                Há, ainda, os <strong>dados pessoais sensíveis</strong>, que são dados pessoais
                &quot;sobre origem racial ou étnica, convicção religiosa, opinião política, filiação
                a sindicato ou a organização de caráter religioso, filosófico ou político, dado
                referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a
                uma pessoa natural&quot; (art. 5º, I).
              </p>{' '}
              <p>
                <strong>Titular dos dados</strong>, por sua vez, é pessoa natural a quem se referem
                os dados pessoais que são objeto de tratamento (art. 5º, V).
              </p>
              <p>
                Já o <strong>tratamento</strong> é qualquer ação que se faça com os dados pessoais
                ou dados pessoais sensíveis. A LGPD aponta como tratamento &quot;toda operação
                realizada com dados pessoais, como as que se referem a coleta, produção, recepção,
                classificação, utilização, acesso, reprodução, transmissão, distribuição,
                processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da
                informação, modificação, comunicação, transferência, difusão ou extração&quot; (art.
                5º, X).
              </p>{' '}
              <p>
                No <strong>capítulo II</strong> são apresentados os requisitos para o tratamento de
                dados pessoais, No capítulo II são apresentados os requisitos para o tratamento de
                dados pessoais, dados pessoais sensíveis, dados pessoais de criança e de
                adolescente, e as hipóteses de término do tratamento de dados.
              </p>{' '}
              <p>
                Os <strong>direitos dos titulares</strong> são apresentados no{' '}
                <strong>capítulo III</strong>, com a descrição dos prazos e formas para o
                atendimento das requisições dos titulares.
              </p>{' '}
              <p>
                O <strong>capítulo IV</strong> é dedicado{' '}
                <strong>ao tratamento de dados pessoais pelo Poder Público</strong> e a sua
                responsabilização em caso de infração à LGPD.
              </p>{' '}
              <p>
                O <strong>capítulo V</strong> trata da{' '}
                <strong>transferência internacional de dados</strong>, e o capítulo VI se ocupa dos
                agentes de tratamento de dados pessoais, da responsabilidade dos agentes e do
                ressarcimento de danos.
              </p>{' '}
              <p>
                Os <strong>agentes de tratamento de dados pessoais</strong> são três: o{' '}
                <strong>controlador</strong>, o <strong>operador</strong> e o{' '}
                <strong>encarregado</strong> pelo tratamento de dados pessoais.
              </p>{' '}
              <p>
                Conforme os conceitos apresentados pela própria LGPD, o <strong>controlador</strong>{' '}
                é a &quot;pessoa natural ou jurídica, de direito público ou privado, a quem competem
                as decisões referentes ao tratamento de dados pessoais&quot; (art. 5º, VI), enquanto
                o <strong>operador</strong> é a &quot;pessoa natural ou jurídica, de direito público
                ou privado, que realiza o tratamento de dados pessoais em nome do controlador&quot;
                (art. 5º, VII).
              </p>{' '}
              <p>
                O <strong>encarregado</strong> pelo tratamento de dados pessoais, por seu turno, é a
                &quot;pessoa indicada pelo controlador e operador para atuar como canal de
                comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de
                Proteção de Dados (ANPD)&quot;.
              </p>{' '}
              <p>
                O <strong>capítulo VII</strong> cuida da{' '}
                <strong>segurança e das boas práticas</strong> a serem adotadas no tratamento de
                dados pessoais, enquanto o <strong>capítulo VIII</strong> trata da{' '}
                <strong>fiscalização</strong> da proteção de dados pessoais, com destaque para o rol
                de sanções administrativas que podem ser aplicadas pela ANPD.
              </p>{' '}
              <p>
                A <strong>Autoridade Nacional de Proteção de Dados</strong> (ANPD), órgão da
                administração pública federal, integrante da Presidência da República, e o{' '}
                <strong>Conselho Nacional de Proteção de Dados Pessoais e da Privacidade</strong>{' '}
                são especificados no <strong>capítulo IX</strong>.
              </p>{' '}
              <p>
                Por fim, o <strong>capítulo X</strong> é dedicado às{' '}
                <strong>disposições finais e transitórias</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
