import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaDoorOpen, FaUser } from 'react-icons/fa';
import { FiMail, FiSettings } from 'react-icons/fi';
import { delCookies } from '../../assets/scripts/global';
import SidebarMenu from '../SidebarMenu';

// import './antd/dist/reset.css';
import '../../../../custom.scss';
import '../../admin.scss';
import './style.scss';
import logo from '../../../../img/logo-light.png';

export default function Navbar() {
  const history = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  let permissions;

  // funcao para verificar se o cookie esta vazio
  // sem a verificacao retorna erro de JSON.parse vazio
  if (localStorage.getItem('nbw__permissions')) {
    permissions = JSON.parse(localStorage.getItem('nbw__permissions'));
  }

  // Evento botão hamburger
  function toggleHamburger() {
    if (document.getElementById('sidebarMenu').classList.contains('collapsed')) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }

  if (window.location.pathname !== '/Login') {
    return (
      <>
        <nav id="navbar" className="navbar navbar-expand-lg navbar-dark fixed-top">
          <div className="container-fluid">
            <div className="d-flex align-items-center">
              <Link to="/" className="navbar-brand">
                <img src={logo} height="50px" title="NBW Digital" alt="Logo NBW Digital" />
              </Link>
              <div
                className="hamburger ms-4"
                id="hamburger-1"
                onClick={() => {
                  toggleHamburger();
                }}
                role="button"
                tabIndex="0"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    toggleHamburger();
                  }
                }}
              >
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </div>
            </div>

            {/* Dropdown usuario */}
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle"
                  type="button"
                  id="dropDownUser"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {(() => {
                    const userDataString = localStorage.getItem('nbw__userData');
                    if (userDataString) {
                      try {
                        const userData = JSON.parse(userDataString);
                        const login = userData.login;

                        if (login) {
                          return login.indexOf('.') === -1
                            ? login.substr(0, 2).toUpperCase()
                            : login.split('.')[1].substr(0, 1).toUpperCase();
                        }
                      } catch (error) {
                        console.error('Error parsing JSON:', error);
                      }
                    }
                    return 'Guest';
                  })()}
                </button>

                <ul className="dropdown-menu" aria-labelledby="dropDownUser">
                  {JSON.parse(localStorage.getItem('nbw__userData')).id !== 1 ? (
                    <li>
                      <button className="dropdown-item" type="button">
                        <Link to="/Dashboard/UserProfile">
                          <FaUser size="16" color="#fff" className="me-2" />
                          Meu Perfil
                        </Link>
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}

                  <li>
                    <button className="dropdown-item" type="button">
                      <Link to="/Apontamento/AssinaturaEmail">
                        <FiMail size="16" color="#fff" className="me-2" />
                        Assinatura E-Mail
                      </Link>
                    </button>
                  </li>

                  <li>
                    {permissions[24].view && permissions[24].insert && (
                      <button className="dropdown-item" type="button">
                        <Link to="/Apontamento/Configuracao">
                          <FiSettings size="16" color="#fff" className="me-2" />
                          Configurações
                        </Link>
                      </button>
                    )}
                  </li>

                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => delCookies(true, history)}
                    >
                      <FaDoorOpen size="16" color="#fff" className="me-2" />
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>

        <SidebarMenu collapsed={collapsed} />
      </>
    );
  }

  return null;
}
